import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Login from '../views/Login.vue'
import LoginFSP from '../views/LoginFSP.vue'
import Demo from '../views/Demo.vue'
import Main from '../views/Main.vue'
import Home from '../views/Home.vue'
import IrpDashboard from '../views/irp/IrpDashboard.vue'
import StudekDashboard from '../views/studek/StudekDashboard.vue'
import PatzuDashboard from '../views/patzu/PatzuDashboard.vue'
import MoniqDashboard from '../views/moniq/MoniqDashboard.vue'
import ResponsibleCheck from '../views/ResponsibleCheck.vue'
import Items from '../views/Items.vue'
import Item from '../views/Item.vue'
import Logout from '../views/Logout.vue'
import PageNotFound from '../views/PageNotFound.vue'
import PageNoAccess from '../views/PageNoAccess.vue'

Vue.use(VueRouter)

const routes = [
	{ path: '/demo/:token', component: Demo, meta: { requiresAuth: false } },
	{ path: '/reset/:token', component: () => import('../views/ResetPassword.vue'), meta: { requiresAuth: false } },
	{ path: '/signup/:token', component: () => import('../views/SignUp.vue'), meta: { requiresAuth: false } },
	{ path: '/signupFSP/:token', component: () => import('@/views/SignUpFSP.vue'), meta: { requiresAuth: false } },
	{
		path: '',
		component: Main,
		meta: { requiresAuth: true },
		children: [
			{ path: '', component: Home }
		]
	},
	{
		path: '/',
		component: Main,
		meta: { requiresAuth: true },
		children: [
			{ path: '/', component: Home, meta: { requiresAuth: true } },
			// { path: '/results/evaluations/+', component: AnalyticsItem, meta: { app: 'results' } },
			// { path: '/results/evaluations/:primaryKey', props: { app: 'results' }, component: AnalyticsItem },
			{ name: 'Patientenzufriedenheit - Dashboard', path: '/patzu', component: PatzuDashboard, meta: { requiresAuth: true, staffOnly: true } },
			{ name: 'IRP - Reviews', path: '/irp', component: IrpDashboard, meta: { requiresAuth: true } },
			{ name: 'MoniQ - Dashboard', path: '/moniq', component: MoniqDashboard, meta: { requiresAuth: true, staffOnly: true } },
			{ name: 'Studek - Dashboard', path: '/studek', component: StudekDashboard, meta: { requiresAuth: true, staffOnly: true } },
			{ name: 'Items', path: '/:app/:collection/', component: Items, meta: { requiresAuth: true } },
			{ name: 'DetailItem', path: '/:app/:collection/:primaryKey', component: Item, meta: { requiresAuth: true } }
		]
	},
	{ path: '/responsible', component: ResponsibleCheck, meta: { requiresAuth: false } },
	{ path: '/login', component: Login, meta: { requiresAuth: false } },
	{ path: '/loginFSP', component: LoginFSP, meta: { requiresAuth: false } },
	{ path: '/demo', component: Demo, meta: { requiresAuth: false } },
	{ path: '/logout', component: Logout, meta: { requiresAuth: false } },
	{ path: '/404', component: PageNotFound, meta: { requiresAuth: false } },
	{ path: '/403', component: PageNoAccess, meta: { requiresAuth: false } },
	{ path: '*', redirect: '/404' }
]

const router = new VueRouter({
	mode: 'history',
	linkExactActiveClass: 'active',
	routes
})

const staffOnlySites = [
	// Apps
	'apps/apps',
	'apps/groups',
	// Patzu
	'patzu/hospitals',
	'patzu/users',
	'patzu/responsibles',
	'patzu/addresses',
	'patzu/measures',
	'patzu/event',
	'patzu/orders',
	'patzu/orderunits',
	'patzu/declarations',
	'patzu/tokens',
	// Moniq
	'moniq/hospitals',
	'moniq/users',
	'moniq/responsibles',
	// Studek
	'studek/hospitals',
	'studek/users',
	'studek/measures',
	'studek/events',
	// Results
	'results/measures',
	'results/data',
	'results/importer',
	// Mailer
	'mailer/mailjobs',
	'mailer/mailtemplates',
	'mailer/mails',
	// Files
	'filemanager/files',
	// Acount
	'accounts/versions',
	'accounts/usertitles',
	'accounts/feedbacks'
]

router.beforeEach(async (to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (store.state.accessToken === null && to.path !== '/login') {
			let redirectObj = null
			if (to.fullPath !== '/') {
				const json = '{"' + decodeURI(to.fullPath.substring(2)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
				try {
					redirectObj = JSON.parse(json)
				} catch (_) {
					redirectObj = null
				}
			}

			let query = redirectObj ? { redirect: redirectObj.redirect } : {}
			if (to.fullPath !== '/') {
				query.app = to.fullPath
			}

			next({
				path: '/login',
				query: query
			})
			return
		}
		// initalize store values
		if (!store.state.initialized) {
			await store.dispatch('initialize')
		}
		if (to.name === 'Items' && store.getters[`${to.params.app}/${to.params.collection}/all`] === undefined) {
			next({ path: '/404' })
			return
		}
		// Check only Staff sites
		if (to.matched.some(record => record.meta.staffOnly === true) && !store.getters.isStaff) {
			next({ path: '/403' })
			return
		}
		if (!store.getters.isStaff && staffOnlySites.includes(`${to.params.app}/${to.params.collection}`) && (to.path !== '/accounts/feedbacks/+')) {
			next({ path: '/403' })
			return
		}
		try {
			next()
		} catch (err) {
			console.log(err)
		}
	} else {
		if (to.path === '/login' && store.state.accessToken !== null) {
			next({
				path: '/'
			})
			return
		}
		next()
	}
})

export default router
