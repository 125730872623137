import Crud from '../../crud'
import { i18n } from '@/main.js'
import ApiClient from '@/assets/js/ApiClient.js'
let crud = new Crud('results/evaluations')

export default {
	namespaced: true,
	state: () => ({
		...crud.state,
		currentModel: null,
		toolTip: null,
		selected: 'all'
	}),
	getters: {
		...crud.getters,
		moniqAdmins: (state, getters, rootState, rootGetters) => {
			const detailItem = rootGetters['results/evaluations/detailItem']
			return detailItem && detailItem.view_access_admins ? detailItem.view_access_admins : null
		},
		canCreateEvalChildEvaluation: (state) => {
			return state?.detailItem?.is_parent_evaluation
		},
		charts: (state) => { return state?.detailItem?.charts },
		selectedCharts: (state) => { return state?.detailItem?.selected_charts_ids },
		parentCharts: (state) => { return state?.detailItem?.parent_charts},
		childrenIds: (state) => {
			return state?.detailItem?.children_ids_and_selected_charts_ids_for_children.map((childrenIdsAndSelectedChartsIdsObj) => childrenIdsAndSelectedChartsIdsObj.child_id)
		},
		anqMeasureOnly (state) {
			const anqMeasures = [113, 61, 62, 63]
			return anqMeasures.some((measureNumber) => state?.detailItem?.measures.includes(measureNumber))
		},
		fspMeasureOnly (state) {
			const fspMeasures = [65, 66, 101]
			return fspMeasures.some((measureNumber) => state?.detailItem?.measures.includes(measureNumber))
		},
		selectedChartsIdsForChildren: (state) => {
			return state?.detailItem?.children_ids_and_selected_charts_ids_for_children[0]?.selected_charts_ids_for_children
		},
		getParentEvalId: (state) => { const parentId = state?.detailItem?.parent_evaluation_id; return parentId ? parentId : null },
		customAddView: () => 'evaluations/AddEvaluation',
		customEditView: () => 'evaluations/EditEvaluation',
		title: (state, getters, rootState, rootGetters) => () => {
			const item = getters.detailItem
			if (item) {
				if (rootGetters.user.language === 2) {
					return item.name_fr || item.name
				} else if (rootGetters.user.language === 3) {
					return item.name_it || item.name
				} else {
					return item.name
				}
			} else {
				return i18n.t('results.evaluations')
			}
		},
		canDeleteMulti: () => false,
		canSave: (state, getters, rootState, rootGetters) => (item) => {
			return (item !== null && item !== undefined)
				? (item.creator_id === rootGetters.user.id ||
          rootGetters.user.is_staff !== undefined ||
          ((getters.moniqAdmins || item.users_admin) && (getters.moniqAdmins || item.users_admin).includes(rootGetters.user.id)))
				: false
		},
		canClone: (state, getters, rootState, rootGetters) => (item) => {
			return item !== null && (item.creator_id === rootGetters.user.id || (getters.moniqAdmins || item.users_admin).includes(rootGetters.user.id) || rootGetters.user.is_staff)
		},
		canDelete: (state, getters, rootState, rootGetters) => () => {
			const item = state.detailItem
			return item !== null && (item.creator_id === rootGetters.user.id || (getters.moniqAdmins || item.users_admin).includes(rootGetters.user.id) || rootGetters.user.is_staff)
		},
		filters (state, getters, rootState, rootGetters) {
			if (!rootGetters.user.is_staff) {
				return undefined
			}
			return [
				{
					name: 'Messungen',
					options: rootGetters['results/measures/all'].map(m => {
						let label = ''
						if (!m.is_parent_measure) {
							label = m.label ? ` - ${m.label}` : ' - (no label)'
						}

						const inheritData = !m.is_parent_measure
							? (m.inherit_data ? ' [Inherits data]' : ' [Does not inherit data]')
							: ''

            return {
              id: m.id,
              value: `${m.name}${label}${inheritData}`
            }
          }),
          field: 'measures__ids'
        },
      {
        name: 'Ersteller',
        options: [
          { id: 'true', value: 'Staff' },
          { id: 'false', value: 'User' }
        ],
        field: 'creator'
       }
      ] 
    },
    fields (state, getters, rootState, rootGetters) {
      // const w2logo = '<div class="flex items-center justify-center bg-red-600 w-6 h-6 text-xs rounded-full text-white font-bold">w²<div>'
      const checkHide = (item) => {
        return item && item.creator_id ? item.creator_id === rootGetters.user.id || rootGetters.user.is_staff : false
      }
      const user = rootGetters.user
      const checkHideList = () => {
        return !user.is_staff
      }

			return [
				{ text: 'Parent', value: 'is_parent_evaluation', type: 'boolean', format: 'BooleanView', hideCreate: true, hideEdit: true, width: 70 },
				{ text: i18n.t('description'), width: -1, value: 'name', hideEdit: (item) => !checkHide(item), showSelect: true },
				{ text: i18n.t('evaluation'), width: 100, values: ['measures', 'object_id', 'content_type', 'charts'], type: 'component', componentName: 'evaluations/Evaluation', hideList: true },
				{ text: 'Child label', value: 'label', max_length: 40, width: -1, default: '', required: false, hideEdit: false, hideCreate: false, showSelect: true, // getters.canCreateChildMeasure
					renderItem: (value, item) => {
						return (!item.is_parent_evaluation && (!value || value.trim() === '')) ? '<EM>(no label)</EM>' : value
					}
				},
				{
					text: i18n.t('measures'),
					width: 250,
					value: 'measures',
					hideList: checkHideList,
					renderItem: (value) => {
						const mes = rootGetters['results/measures/getById'](value)
						let tmp = '<div style="font-size: 0.8em; line-height: 1.2;">'

						if (mes && typeof mes === 'object') {
							if (mes.is_parent_measure) {
								tmp += `${mes.name}`
							} else {
								tmp += `${mes.name}`
								tmp += mes.label ? ` - <EM>${mes.label}</EM>` : ' - <EM>(no label)</EM>'
								// tmp += mes.inherit_data ? ' [Inherits data]' : ' [Does not inherit data]'
							}
							tmp += '</div>'
							return tmp
						}

						return '<div style="font-size: 0.8em;">Measure not found</div>'
					}
				},
				{ text: i18n.t('created-by'), value: 'creator.email', order: 'creator__email', width: 200, hideEdit: true, hideCreate: true, hideList: checkHideList },
				{ text: i18n.t('created-at'), value: 'created', format: 'DateTime', width: 150, readOnly: true, hideEdit: true, hideCreate: true, hideList: checkHideList },
				{ text: i18n.t('Letzter Zugriff'), value: 'modified', format: 'DateTime', width: 150, readOnly: true, hideEdit: true, hideCreate: true, hideList: checkHideList }
			]
		}
	},
	mutations: {
		...crud.mutations,
		storeToolTip (state, item) {
			state.toolTip = item
		},
		setSelected (state, selected) {
			state.selected = selected
		}
	},
	actions: {
		...crud.actions,
		// eslint-disable-next-line
    async updateParentAfterDeletion ({}, endpoint) {
			ApiClient.post(`${endpoint}`).then(() => {})
		},
		// eslint-disable-next-line
    async updateEvalCharts ({}, { endpoint, newBody }) {
			ApiClient.put(`${endpoint}`, newBody).then(() => {})
		}
	}
}
