import ApiClient from '../../../assets/js/ApiClient.js'

const groupBy = (array, key) => {
	// Return the end result
	return array.reduce((result, currentValue) => {
		// If an array already present for key, push it to the array. Else create an array and push the object
		(result[currentValue[key]] = result[currentValue[key]] || []).push(
			currentValue
		)
		// Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
		return result
	}, {}) // empty object is the initial value for result object
}

export default {
	namespaced: true,
	state: () => ({
		all: []
	}),
	getters: {
		all: state => state.all,
		grouped: state => groupBy(state.all, 'content_type_id'),
		getById: (state) => (id) => { return state.all.find(permission => permission.id === parseInt(id)) },
		hasAccess: (state, getters, rootState, rootGetters) => (name) => {
			const names = name.split('.')
			const model = names[1].split('_')[1]
			const contentType = rootGetters['accounts/contenttypes/getByName'](`${names[0]}.${model}`)
			if (!contentType) {
				return false
			}
			const perm = state.all.find(permission => permission.codename === names[1] && permission.content_type_id === contentType.id)
			return perm !== undefined
		}
	},
	mutations: {
		setPermissions (state, permissions) {
			state.all = permissions
		},
		reset (state) {
			state.all = []
		}
	},
	actions: {
		load ({ commit, dispatch }) {
			let prom = new Promise((resolve) => {
				dispatch('startLoading', null, { root: true })
				ApiClient.get('accounts/permissions/').then(response => {
					commit('setPermissions', response.data)
				}).finally(() => {
					dispatch('endLoading', null, { root: true })
					resolve()
				})
			})
			return prom
		}
	}
}
