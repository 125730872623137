<template>
  <div class="relative">
    <div v-if="languages.length > 1" class="absolute right-1 top-2">
      <ul class="flex text-xs">
        <li v-if="languages.indexOf(1) > -1" class="ml-1 mr-1">
          <a
            @click="setActiveTab('de')"
            class="inline-block border rounded p-1 cursor-pointer w-7 text-center"
            :class="[isActive('de') ? 'border-primary bg-primary dark:bg-white text-white dark:text-black' : 'border-white dark:border-black hover:border-neutral-200 dark:hover:border-neutral-800 dark:hover:bg-neutral-800 text-primary dark:text-white hover:bg-neutral-200 dark:bg-neutral-600']"
          >De</a>
        </li>
        <li v-if="languages.indexOf(2) > -1" class="mr-1">
          <a
            @click="setActiveTab('fr')"
            class="inline-block border rounded p-1 cursor-pointer w-7 text-center"
            :class="[isActive('fr') ? 'border-primary bg-primary dark:bg-white text-white dark:text-black' : 'border-white dark:border-black hover:border-neutral-200 dark:hover:border-neutral-800 dark:hover:bg-neutral-800 text-primary dark:text-white hover:bg-neutral-200 dark:bg-neutral-600']"
          >Fr</a>
        </li>
        <li v-if="languages.indexOf(3) > -1" class="mr-1">
          <a
            @click="setActiveTab('it')"
            class="inline-block border rounded p-1 cursor-pointer w-7 text-center"
            :class="[isActive('it') ? 'border-primary bg-primary dark:bg-white text-white dark:text-black' : 'border-white dark:border-black hover:border-neutral-200 dark:hover:border-neutral-800 dark:hover:bg-neutral-800 text-primary dark:text-white hover:bg-neutral-200 dark:bg-neutral-600']"
          >It</a>
        </li>
      </ul>
    </div>
    <input
      v-if="debounce"
      type="text"
      :value="value[getKey]"
      :placeholder="placeholder"
      class="pr-28 truncate"
      v-debounce:500="updateText"
      :disabled="disabled"/>
    <!-- eslint-disable-next-line --> <!-- here is direct modification of props!-->
  <input v-else type="text" v-model="value[getKey]" :placeholder="placeholder" class="pr-28 truncate" @input="$emit('input')" :disabled="disabled">
  </div>
</template>

<script>

/* eslint-disable */     //there is direct modification of props that is an bad sign
export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    labelKey: {
      type: String,
      required: true
    },
    languages: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    debounce: {
      type: Boolean,
      default: false
    },
    currentLanguage: {
      type: String,
      default: 'de'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    if (this.labelKey === 'title') {
      return {
        activeTab: this.value.languageTabTitle
      }
    } else if (this.labelKey === 'subtitle') {
      return {
        activeTab: this.value.languageTabSubtitle
      }
    } else {
      return {
        activeTab: this.currentLanguage
      }
    }
  },
  computed: {
    getActiveTab () {
      return this.activeTab
    },
    getKey () {
      if (this.getActiveTab === 'fr') {
        return `${this.labelKey}_fr`
      } else if (this.getActiveTab === 'it') {
        return `${this.labelKey}_it`
      }
      return this.labelKey
    }
  },
  methods: {
    setActiveTab (language) {
      this.activeTab = language
      if (this.labelKey === 'title') {
        this.value.languageTabTitle = language
      } else if (this.labelKey === 'subtitle') {
        this.value.languageTabSubtitle = language
      }
    },
    isActive (value) {
      return this.getActiveTab === value
    },
    updateText (value) {
      this.value[this.getKey] = value
    }
  }
}
</script>
