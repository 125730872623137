
<template>

  <div class="w-full">
    <loading-spinner v-if="isLoading && !mounted"/>
    <div v-if="isLoading" class="z-50 fixed w-full h-full cursor-wait backdrop-filter backdrop-blur-sm"></div>
    <div v-if="items" class="w-fullrounded-lg">
      <h3 class="mb-2 font-bold">⚠️ Ungültige Anzahl Administratoren ⚠️ </h3>
      <v-table
        :headers="headers"
        :items="items.results"
        :selectable="true"
        @click="onClick"/>
      <Pagination :paginationInitial="pagination" @on-new-page="onNewPage"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VTable from '@/components/v-table'
import Pagination from '@/components/Pagination'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
export default {
	components: {
		'v-table': VTable,
		Pagination,
		LoadingSpinner
	},
	data () {
		return {
			items: null,
			mounted: false
		}
	},
	computed: {
		...mapGetters({
			isLoading: 'isLoading'
		}),
		headers () {
			let tmp = this.$store.getters['moniq/hospitals/fields'].filter(f => f.showSelect)
			tmp.unshift({ text: 'Admins', value: 'admins', width: 80 })
			return tmp
		},
		pagination () {
			return {
				count: this.items.count,
				current: this.items.current,
				next: this.items.next,
				previous: this.items.previous,
				size: 5
			}
		}
	},
	methods: {
		async onNewPage (newPage) {
			this.mounted = true
			this.items = await this.$store.dispatch('moniq/hospitals/load', { callback: true, pagination: true, query: { adminCheck: 1, checkPermissions: 1, size: 5, page: newPage } })
		},
		onClick (item) {
			this.$router.push({ path: `moniq/hospitals/${item.id}` })
		}
	},
	async mounted () {
		this.mounted = false
		this.items = await this.$store.dispatch('moniq/hospitals/load', { callback: true, pagination: true, query: { adminCheck: 1, checkPermissions: 1, size: 5 } })
	}
}
</script>
