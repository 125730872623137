import Crud from '../../crud'
import ApiClient from '@/assets/js/ApiClient.js'
let crud = new Crud('irp/reviews')

export default {
  namespaced: true,
  state: () => ({
    ...crud.state
  }),
  getters: {
    ...crud.getters,
    hasHistory: () => false,
    queryGet: () => { return { byPermissions: 0 } },
    canClone: () => () => {
      return true
    },
    fields () {
      return [
        { text: 'Name', value: 'name', width: -1, showSelect: true },
        {
          text: 'Review Year',
          value: 'review_year',
          type: 'rel_select',
          getterPath: 'irp/review_years',
          hideList: true
        },
        {
          text: 'Spital',
          value: 'hospital_id',
          type: 'rel_select',
          getterPath: 'irp/hospitals',
          hideList: true
        },
        {
          text: 'Spital',
          value: 'hospital.name',
          readOnly: true,
          hideCreate: true,
          hideEdit: true,
          showSelect: true,
          width: 200
        },
        { text: 'Datum', value: 'date', type: 'date', format: 'Date', width: 120, showSelect: true },
        { text: 'Treffpunkt', value: 'meeting_point', width: 200, showSelect: true, required: false },
        {
          text: 'Teilnehmer',
          value: 'reviewers',
          type: 'component',
          componentName: 'irp/Review',
          hideList: true,
          required: false
        },
        { text: 'Teilnehmer', value: 'reviewers', required: false, width: 100, hideEdit: true, hideCreate: true, renderItem: (value) => value.length },
        { text: 'Dateien', value: 'files', type: 'component', componentName: 'Files', hideCreate: true, width: 120 }

			]
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions,
		async updateReview ({ dispatch }, { id, state }) {
			await ApiClient.post(`irp/reviews/${id}/answer/`, { state: state })
			await dispatch('load', { all: true })
		}
	}
}
