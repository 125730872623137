import Crud from '../../crud'

let crud = new Crud('accounts/groups')

export default {
	namespaced: true,
	state: () => ({
		...crud.state
	}),
	getters: {
		...crud.getters,
		fields () {
			return [
				{ text: 'Name', value: 'name' }
			]
		},
		groupPermissions: (state, getters, rootState, rootGetters) => (groupId) => {
			const group = getters.getById(groupId)
			return group.permissions.map(p => rootGetters['permissions/getById'](p))
		},
		hasAccess: (state, getters, rootState, rootGetters) => (groupId, name) => {
			return getters.groupPermissions(groupId).find(permission => {
				const names = name.split('.')
				const contentType = rootGetters['contenttypes/getById'](permission.content_type_id)
				return (contentType.app_label === names[0] && contentType.model === names[1])
			}) !== undefined
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions
	}
}
