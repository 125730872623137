import Crud from '../../crud'

let crud = new Crud('patzu/orderunits')

export default {
	namespaced: true,
	state: () => ({
		...crud.state
	}),
	getters: {
		...crud.getters,
		exportActions: () => [
			{
				name: 'Bestellungseinheiten',
				path: 'patzu/orderunits/export/'
			}
		],
		filters (state, getters, rootState, rootGetters) {
			return [
				{
					name: 'Status',
					options: getters.orderStates,
					field: 'states'
				},
				{
					name: 'Kategorien',
					options: rootGetters['patzu/hospitals/categories'],
					field: 'order__hospital__category'
				},
				{
					name: 'Jahr',
					options: rootGetters.years.map(y => { return { id: y, value: `${y}` } }),
					field: 'order__year'
				}
			]
		},
		orderStates: () => [
			{ id: 1, value: 'Bestellung erstellt', color: 'bg-neutral-500' },
			{ id: 2, value: 'Bestellung abgeschlossen', color: 'bg-emerald-200' },
			{ id: 3, value: 'Genehmigt', color: 'bg-blue-400' },
			{ id: 4, value: 'Erstelle Tokens', color: 'bg-orange-400' },
			{ id: 5, value: 'Tokens erstellt', color: 'bg-orange-400' },
			{ id: 6, value: 'Erstelle PDF', color: 'bg-orange-400' },
			{ id: 7, value: 'PDF erstellt', color: 'bg-orange-400' },
			{ id: 8, value: 'PDFs werden geprüft', color: 'bg-orange-400' },
			{ id: 9, value: 'PDF bereit bei Funke', color: 'bg-emerald-400' },
			{ id: 10, value: 'PDF gedruckt / versendet', color: 'bg-emerald-600' },
			{ id: 99, value: 'Fehler', color: 'bg-red-400' }
		],
		fields (state, getters) {
			return [
				{ text: 'Bestellung', value: 'order', type: 'rel_select', getterPath: 'patzu/orders', hideList: true, hideEdit: true },

				{
					text: 'Status',
					value: 'state',
					hideCreate: true,
					type: 'select',
					width: 250,
					showSelect: true,
					options: getters.orderStates
				},
				{ text: 'Spital', value: 'hospital_name', width: 400, hideCreate: true, readOnly: true },
				{ text: 'Auswertungseinheit', value: 'name', width: -1 },
				{ text: '🇩🇪', value: 'count_de', type: 'integer', width: 100 },
				{ text: '🇫🇷', value: 'count_fr', type: 'integer', width: 100 },
				{ text: '🇮🇹', value: 'count_it', type: 'integer', width: 100 }
			]
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions
	}
}
