<template>
  <div>
    <div
      @click.stop="isOpen = !isOpen"
      v-tooltip="'Exportieren...'"
      class="flex items-center justify-center p-2 mr-2 w-10 h-10 bg-blue-700 hover:bg-opacity-80 text-white cursor-pointer rounded-full"
    >
      <svg
        v-if="isLoading"
        class="animate-spin h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"/>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
      </svg>
      <svg
        v-else
        class="fill-current h-5 w-5"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill-rule="evenodd"
        clip-rule="evenodd"><path d="M16 2v7h-2v-5h-12v16h12v-5h2v7h-16v-20h16zm2 9v-4l6 5-6 5v-4h-10v-2h10z"/></svg>
    </div>
    <div v-if="isOpen" @click.stop="isOpen = false" class="z-30 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
      <div class="rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
        <div class="py-1">
          <a
            v-for="(act, index) in actions"
            :key="index"
            @click="getData(act)"
            href="#"
            class="block px-4 py-2 text-sm leading-5 text-neutral-700 hover:bg-neutral-100 hover:text-neutral-900 focus:outline-none focus:bg-neutral-100 focus:text-neutral-900"
            role="menuitem"
          >
            {{act.name}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiClient from '@/assets/js/ApiClient.js'
import { mapGetters } from 'vuex'
export default {
	components: {
	},
	props: {
		actions: {
			type: Array,
			required: true
		}
	},
	data () {
		return {
			loading: false,
			isOpen: false
		}
	},
	computed: {
		...mapGetters(['query']),
		isLoading () {
			return this.loading
		},
		path () {
			return `${this.$route.params.app}/${this.$route.params.collection}`
		}
	},
	methods: {
		async getData (action) {
			this.loading = true
			// Get current filter
			let query = ''

			// Check if pass the object ids as query
			if (action.relationship_id_field !== undefined && this.query && Object.keys(this.query).length) {
				const path = `${this.path}/load`
				const objects = await this.$store.dispatch(path, { query: this.query, callback: true, all: true })
				const ids = objects.map(obj => obj.id)
				action.path.includes('?') ? query = query + '&' : query = '?'
				query = `${query}&${action.relationship_id_field}=${ids.join(',')}`
			} else {
				if (this.query && Object.keys(this.query).length) {
					action.path.includes('?') ? query = query + '&' : query = '?'
					query = query + Object.keys(this.query).map(key => key + '=' + this.query[key]).join('&')
				}
			}

			ApiClient.get(`${action.path}${query}`).then(response => {
				const blob = new Blob([response.data], { type: 'text/csv' })
				const url = window.URL.createObjectURL(blob)
				const a = document.createElement('a')
				a.href = url

				const title = action.name.toLowerCase()
				a.download = `export_${title}.csv`
				a.click()
				// 5. Release this temporary object url
				window.URL.revokeObjectURL(url)
				this.loading = false
			}).catch((err) => {
				this.$store.dispatch('addNotification', { title: this.$t('error'), message: err.response.data, error: true })
				this.loading = false
			})
		}
	},
	async mounted () {
	}
}
</script>
