import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import globalData from './config.js'
import './assets/styles/index.css'
import messages from './assets/lang'
import VTooltip from 'v-tooltip'
import { Modal, Progress } from './plugins/plugins.js'
import vueDebounce from 'vue-debounce'
// import VueCookies from 'vue-cookies'
import PortalVue from 'portal-vue'
import checkView from 'vue-check-view'
import 'vue-easytable/libs/theme-default/index.css'
import VueEasytable from 'vue-easytable'
import TooltipDirective from './directives/custom-tooltip.js'
import VueMatomo from 'vue-matomo'

Vue.use(checkView)
Vue.use(PortalVue)
// Vue.use(VueCookies)
// Vue.$cookies.config('9999d')
Vue.use(VueEasytable)

Vue.directive('tip', TooltipDirective)
Vue.directive('click-outside', {
	bind: function (el, binding, vnode) {
		el.clickOutsideEvent = function (event) {
			// here I check that click was outside the el and his children
			if (!(el === event.target || el.contains(event.target))) {
				// and if it did, call method provided in attribute value
				vnode.context[binding.expression](event)
			}
		}
		document.body.addEventListener('click', el.clickOutsideEvent)
	},
	unbind: function (el) {
		document.body.removeEventListener('click', el.clickOutsideEvent)
	}
})

// Multilang configurations
Vue.use(VueI18n)
export const i18n = new VueI18n({
	locale: 'de',
	fallbackLocale: 'de',
	messages
})
// Google Analytics (removed)
Vue.use(vueDebounce, {
	listenTo: 'input'
})
Vue.use(Modal)
Vue.use(Progress)
Vue.use(VTooltip)

if (process.env.NODE_ENV === 'production') {
	Vue.use(VueMatomo, {
		host: 'https://analytics.w-hoch2.ch/',
		siteId: 1,
		router: router,
		trackInitialView: true,
		enableLinkTracking: true,
		enableHeartBeatTimer: true,
		disableCookies: true,
		debug: false
	})
	window._paq.push(['trackPageView'])
}

Vue.config.productionTip = false
Vue.prototype.$globalData = globalData

new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')
