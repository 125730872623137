import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
require('dayjs/locale/de')
dayjs.extend(relativeTime)
dayjs.locale('de')
export default {
	methods: {
		formatDate (date) {
			if (!date || !dayjs(date, 'DD.MM.YYYY', true).isValid()) {
				console.log('Invalid date or format')
				return '-'
			}

			return dayjs(date, 'DD.MM.YYYY').format('DD.MM.YYYY')
		},
		formatDateTime (date) {
			return date ? dayjs(date).format('DD.MM.YYYY HH:mm') : '-'
		},
		formatRelativeTime (date) {
			return dayjs(date).fromNow()
		}
	}
}
