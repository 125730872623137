import Crud from '../../crud'
import { i18n } from '@/main.js'

let crud = new Crud('studek/hospitals')

export default {
	namespaced: true,
	state: () => ({
		...crud.state
	}),
	getters: {
		...crud.getters,
		canDeleteMulti: () => false,
		neededCollections () {
			return ['studek/measures']
		},
		hasHistory: () => true,
		contentType: (state, getters, rootState, rootGetters) => rootGetters['accounts/contenttypes/getByName']('studek.hospital').id,
		filters (state, getters, rootState, rootGetters) {
			return [
				{
					name: 'Status',
					options: getters.states,
					field: 'states'
				},
				{
					name: 'Kategorien',
					options: getters.categories,
					field: 'categories'
				},
				{
					name: 'Messung',
					options: rootGetters['studek/measures/all'].map(data => { return { id: data.id, value: data.name } }),
					field: 'measures__ids'
				},
			]
		},
		exportActions (state, getters, rootState, rootGetters) {
			const ct = rootGetters['accounts/contenttypes/getByName']('studek.hospital').id
			return [
				{
					name: 'Spitalliste',
					path: 'studek/hospitals/export/?byPermissions=0'
				},
				{
					name: 'Berechtigungsliste',
					path: `accounts/app_permissions/export/?content_type=${ct}`,
					relationship_id_field: 'object_ids'
				}
			]
		},
		title: (state, getters) => () => {
			const item = getters.detailItem
			if (item) {
				return item.name
			} else {
				i18n.t('studek.hospitals')
			}
		},
		canAddMailJob: () => true,
		queryGet: () => { return { byPermissions: 0 } },
		categories: () => [
			{ id: 1, value: 'Psychiatrie' },
			{ id: 2, value: 'Rehabilitation' },
			{ id: 3, value: 'Akutsomatik Erwachsene' },
			{ id: 4, value: 'Akutsomatik Eltern' },
			{ id: 5, value: 'Kinder und Jugendpsychiatrie' }
		],
		states: () => [
			{ id: 1, value: 'Aktiv' },
			{ id: 2, value: 'Geschlossen' },
			{ id: 3, value: 'Dispensiert' }
		],
		fields (state, getters) {
			return [
				{ text: 'Id', value: 'id', hideList: true, hideEdit: true, hideCreate: true },
				{ text: 'Name', value: 'name', width: -1, showSelect: true },
				{ text: 'ANQ-Id', value: 'anqid', type: 'number', width: 70, showSelect: true },
				{
					text: 'Kategorie',
					value: 'category',
					type: 'select',
					width: 220,
					showSelect: true,
					options: getters.categories
				},
				{
					text: 'Status',
					value: 'state',
					type: 'select',
					hideList: true,
					hideCreate: true,
					width: 220,
					options: getters.states
				},
				{
					text: 'Dispensiert bis',
					value: 'dispensed_until',
					type: 'date',
					format: 'Date',
					width: 160,
					hideList: true,
					hideCreate: true,
					hideEdit: (item) => !item || item.state !== 3
				},
				{
					text: 'Messungen',
					value: 'measures_ids',
					type: 'rel_select',
					isMulti: true,
					getterPath: 'studek/measures',
					hideList: true,
					query: (item) => { return item ? { category: item.category } : null },
					initQuery: (item) => { return item !== undefined ? { hospitals__id: item.id } : null }
				},
				{ text: 'Benutzer', value: 'users', type: 'component', componentName: 'Users', width: 120, hideCreate: true, hideList: true },
				{ text: 'Dateien', value: 'files', type: 'component', componentName: 'Files', hideCreate: true, hideList: true, width: 120 },
				{
					text: 'Admins',
					value: 'admins',
					order: 'admins_count',
					width: 80,
					hideCreate: true,
					hideEdit: true,
					renderItem: (value) => {
						if (value.length !== 1) {
							return `<span class="text-red-600 font-bold">${value.length} 👑</span>`
						}
						return `${value.length} 👑`
					}
				},
				{ text: 'Aktiv', value: 'is_active', readOnly: true, hideList: true, hideCreate: true, hideEdit: true, format: 'BooleanView', type: 'boolean', width: 80 }
			]
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions
	}
}
