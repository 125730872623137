<template>
  <div v-if="actions" class="relative">
    <div class="cursor-pointer z-20" @click.stop="isOpen = !isOpen">
      <svg
        class="transform rotate-90 fill-current"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"><path d="M12 18c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"/></svg>
    </div>
    <div v-if="isOpen" @click.stop="isOpen = false" :style="index <= 2 && length >= 6 ? 'bottom:-8px;' : 'top:-16px;'" class="z-30 origin-top-right absolute right-9 mt-2 w-48 rounded-md shadow-lg">
      <div class="rounded-md bg-white dark:bg-neutral-800  shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
        <div class="py-1">
          <a
            v-for="act in actions"
            :key="act.action"
            @click="runAction(act)"
            class="block px-4 py-2 text-sm leading-5 text-neutral-700 dark:text-white hover:bg-neutral-100 dark:hover:bg-neutral-700 hover:text-neutral-900 focus:outline-none focus:bg-neutral-100 focus:text-neutral-900"
            role="menuitem"
            :class="[isDisabled(act) ? 'opacity-40 cursor-not-allowed' : '']"
          >
            {{act.label}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	props: {
		length: {
			type: Number,
			default: null
		},
		index: {
			type: Number,
			default: null
		},
		item: {
			type: Object,
			default: null
		},
		path: {
			type: String,
			default: null
		},
		actionPathName: {
			type: String,
			default: null
		}
	},
	data () {
		return {
			isOpen: false
		}
	},
	computed: {
		actions () {
			if (!this.path) {
				return null
			}
			return this.$store.getters[`${this.path}/${this.actionPathName}`]
		}
	},
	methods: {
		isDisabled (action) {
			return action.isDisabled && action.isDisabled(this.item)
		},
		runAction (action) {
			if (action.warningText) {
				const params = {
					title: 'Warnung',
					text: action.warningText(this.item),
					type: 'warning',
					onConfirm: () => {
						return this.$store.dispatch(`${this.path}/${action.action}`, this.item ? this.item.id : null)
					}
				}
				this.$confirmModal.show(params)
			} else {
				return this.$store.dispatch(`${this.path}/${action.action}`, this.item ? this.item.id : null)
			}
		}
	},
	mounted () {
	}
}
</script>
