<style scoped>
.text-shadow {
    text-shadow: 1px 1px 0 rgb(0 0 0 / 100%), 1px 1px 5px rgb(0 0 0 / 50%)
  }

</style>
<template>
  <div class="flex items-center text-xs">
    <div v-if="unit.state >= state.id">
      ✅
    </div>
    <div v-if="unit.state + 1 == state.id">
      👉
    </div>
    <div v-if="unit.state + 1 < state.id">
      ⬜
    </div>
    <div class="mx-2 w-48" :class="{'font-bold' : unit.state === state.id}">
      {{state.value}}
    </div>

    <div>
      {{unit.name}}
    </div>
    <div class="ml-2 relative w-8 h-8">
      <div class="absolute top-0 left-0 w-full text-center text-2xl opacity-50">🇩🇪</div>
      <div class="absolute top-2 left-0 w-full font-bold text-xs text-center text-white text-shadow">{{unit.count_de}}</div>
    </div>
    <div class="ml-2 relative w-8 h-8">
      <div class="absolute top-0 left-0 w-full text-center text-2xl opacity-50">🇫🇷</div>
      <div class="absolute top-2 left-0 w-full font-bold text-xs text-center text-white text-shadow">{{unit.count_fr}}</div>
    </div>
    <div class="ml-2 relative w-8 h-8">
      <div class="absolute top-0 left-0 w-full text-center text-2xl  opacity-50">🇮🇹</div>
      <div class="absolute top-2 left-0 w-full font-bold text-xs text-center text-white text-shadow">{{unit.count_it}}</div>
    </div>
  </div>
</template>

<script>
export default {
	props: ['unit', 'state']
}
</script>
