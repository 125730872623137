<template>
  <div class="relative w-96 mr-2">
    <div class="absolute -top-5 w-full">
      <input
        ref="searchInput"
        v-model="query.search"
        class="pl-10 rounded-full"
        :placeholder=" formatNumber(tableItemsLength) + ' Einträge'"
        v-debounce:500="searchTextChanged"
        type="text"
      />
      <svg
        class="absolute top-0 left-0 mt-3 ml-3 text-neutral-600 h-5 w-5 fill-current"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Capa_1"
        x="0px"
        y="0px"
        viewBox="0 0 56.966 56.966"
        style="enable-background:new 0 0 56.966 56.966;"
        xml:space="preserve"
        width="512px"
        height="512px"><path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" /></svg>
      <i
        v-if="searchText !== ''"
        class="absolute top-0 right-0 shrink mr-3 mt-2 text-neutral-700 hover:text-red-600 material-icons cursor-pointer"
        @click="resetInput"
      >
        close
      </i>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data: function () {
		return {
			searchText: ''
		}
	},
	mounted () {
		this.resetInput()
	},
	computed: {
		...mapGetters(['tableItemsLength', 'query']),
		path () {
			return `${this.$route.params.app}/${this.$route.params.collection}`
		}
	},
	methods: {
		formatNumber (num) {
			return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'")
		},
		async searchTextChanged (text, depth = 0) {
			const copyQuery = { ...this.query }
			delete copyQuery.page
			const searchQuery = { search: text }
			await this.$store.dispatch(`${this.path}/load`, { query: { ...copyQuery, ...searchQuery } })
			this.query.search = this.query.search ? this.query.search : ''
			// Redo Filtering when the search text has changed in the meantime
			if (text === this.query.search) {
				this.$store.commit('setQuery', { ...copyQuery, ...searchQuery })
			} else {
				if (depth >= 10) {
					// Only purpose of depth variable is to warn about any unforseen inifinite recursion
					console.warn(`The methode 'searchTextChanged' has reached an unusual recursion depth of ${depth}. Shouldn't exceed a depth of about 3 to 5 in the vast majority of cases.`)
				}
				this.searchTextChanged(this.query.search, depth = depth + 1)
			}
		},
		resetInput () {
			this.searchText = ''
			this.searchTextChanged('')
		}
	}
}
</script>
