import { render, staticRenderFns } from "./PatzuDashboard.vue?vue&type=template&id=2e02e290"
import script from "./PatzuDashboard.vue?vue&type=script&lang=js"
export * from "./PatzuDashboard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports