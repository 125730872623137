import Crud from '../../crud'
// import { i18n } from '@/main.js'
let crud = new Crud('irp/reviewers')

export default {
	namespaced: true,
	state: () => ({
		...crud.state
	}),
	getters: {
		...crud.getters,
		hasHistory: () => false,
		teamLeaderTypes: () => [
			{ id: 1, value: 'Ja' },
			{ id: 2, value: 'Nein' },
			{ id: 3, value: 'Potentiell' }
		],
		jobTypes: () => [
			{ id: 1, value: 'Arzt' },
			{ id: 2, value: 'Psychologie' },
			{ id: 3, value: 'Pflege' }
		],
		fields (state, getters, rootState, rootGetters) {
			return [
				{
					text: 'Benutzer',
					value: 'user',
					type: 'rel_select',
					isMulti: false,
					getterPath: 'accounts/users',
					hideList: true,
					query: () => { return { app_permissions__content_type: rootGetters['accounts/contenttypes/getByName']('irp.hospital').id } }
				},
				{ text: 'Benutzer', width: -1, value: 'user_email', hideCreate: true, hideEdit: true, readOnly: true, order: 'user__email', showSelect: true },
				{
					text: 'Teamleader',
					value: 'team_leader',
					type: 'select',
					width: 220,
					options: getters.teamLeaderTypes,
					showSelect: true
				}
				// {
				//   text: 'Berufsgruppe',
				//   value: 'job',
				//   type: 'select',
				//   width: 220,
				//   options: getters.jobTypes,
				//   showSelect: true
				// },
				// { text: i18n.t('mobile'), value: 'phone', width: 200, hideList: true },
				// { text: 'Ausbildung', value: 'education', type: 'textarea', width: 200, hideList: true, maxlength: 255 },
				// { text: 'Kurzbeschrieb Tätigkeit', value: 'description', type: 'textarea', width: 200, hideList: true, maxlength: 2048 }
			]
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions
	}
}
