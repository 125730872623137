<template>
  <div>
    <ul class="flex mb-2">
      <li class="ml-1 mr-1" v-if="!isIrpDashboard">
        <div
          @click="activeTab = 0"
          class="inline-block border rounded py-1 px-3 cursor-pointer"
          :class="[activeTab === 0 ? 'border-primary bg-primary text-white' : 'border-white hover:border-neutral-200 text-primary hover:bg-neutral-200']"
          href="#0">Liste</div>
      </li>
      <li class="mr-1" v-if="!isIrpDashboard">
        <div
          @click="activeTab = 1"
          class="inline-block border rounded py-1 px-3 cursor-pointer"
          :class="[activeTab === 1 ? 'border-primary bg-primary text-white' : 'border-white hover:border-neutral-200 text-primary hover:bg-neutral-200']"
          href="#1">Upload</div>
      </li>
    </ul>
    <v-table
      v-if="activeTab === 0"
      :headers="isIrpDashboard ? getFieldsDashboard : getFields"
      :items="items"
      :selectable="true"
      :ignoreLink="isIrpDashboard ? true : false"
      :isIrpDashboard="isIrpDashboard"
      @click="onClick"
      :path="'filemanager/files'"/>
    <div v-else class="mb-3">
      <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
        <div class="relative border-2 border-neutral-300 border-dashed hover:bg-neutral-100">
          <input
            type="file"
            multiple
            :name="uploadFieldName"
            :disabled="isSaving"
            @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
            class="absolute opacity-0 cursor-pointer w-full h-full"
          />
          <p class="w-full text-center my-10" v-if="isInitial">
            Ziehen Sie Ihre Datei(en) hierher, um zu beginnen<br/> oder klicken Sie zum Durchsuchen
          </p>
          <p class="w-full text-center my-10" v-if="isSaving">
            Hochladen von {{ fileCount }} Dateien...
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import VTable from '@/components/v-table.vue'
import { mapGetters } from 'vuex'
import ApiClient from '@/assets/js/ApiClient.js'

const STATUS_INITIAL = 0; const STATUS_SAVING = 1; const STATUS_SUCCESS = 2; const STATUS_FAILED = 3

export default {
	name: 'Files',
	components: {
		'v-table': VTable
	},
	props: ['value', 'item', 'isIrpDashboard'],
	data () {
		return {
			itemIds: [],
			uploadedFiles: [],
			uploadError: null,
			currentStatus: null,
			uploadFieldName: 'files',
			activeTab: 0
		}
	},
	computed: {
		...mapGetters({
			contentTypeByName: 'accounts/contenttypes/getByName',
			irpUserFiles: 'irp/irpUserFiles/all',
			fields: 'filemanager/files/fields',
			fieldsDashboard: 'filemanager/files/fieldsDashboard'
		}),
		items () {
			return this.value
		},
		getFields () {
			return this.fields.filter(field => field.value !== 'object')
		},
		getFieldsDashboard () {
			return this.fieldsDashboard.filter(field => field.value !== 'object')
		},
		contentType () {
			let routeParamsCollection = this.$route.params.collection

			if (this.$route.params.collection === 'irpGlobalFiles' || this.$route.params.collection === 'irpUserFiles') {
				routeParamsCollection = routeParamsCollection.toLowerCase()
			} else if (this.$route.params.app === 'irp' && this.$route.params.collection === 'users') {
				routeParamsCollection = 'irpuserfiles'
			} else {
				routeParamsCollection = routeParamsCollection.slice(0, -1)
			}

			return this.contentTypeByName(`${this.$route.params.app}.${routeParamsCollection}`).id
		},
		objectId () {
			let objectIdFinal = this.$route.params.primaryKey
			if (this.$route.params.app === 'irp' && this.$route.params.collection === 'users') {
				this.irpUserFiles.forEach((userObject) => {
					if (Number(userObject.user) === Number(objectIdFinal)) {
						objectIdFinal = userObject.id
					}
				})
			}
			return objectIdFinal
		},
		isInitial () {
			return this.currentStatus === STATUS_INITIAL
		},
		isSaving () {
			return this.currentStatus === STATUS_SAVING
		},
		isSuccess () {
			return this.currentStatus === STATUS_SUCCESS
		},
		isFailed () {
			return this.currentStatus === STATUS_FAILED
		}
	},
	methods: {
		onClick (item) {
			if (this.isIrpDashboard) {
				ApiClient.get(`/filemanager/files/${item.id}/download/?byPermissions=0`, { responseType: 'blob' }).then(response => {
					let fileURL = window.URL.createObjectURL(new Blob([response.data]))
					let fileLink = document.createElement('a')
					fileLink.href = fileURL
					fileLink.setAttribute('download', `${item.name}.${item.extension}`)
					document.body.appendChild(fileLink)
					fileLink.click()
				}).catch(err => {
					console.log(err)
				})
				return
			}
			this.$router.push({ path: `/filemanager/files/${item.id}` })
		},
		reset () {
			// reset form to initial state
			this.currentStatus = STATUS_INITIAL
			this.uploadedFiles = []
			this.uploadError = null
		},
		save (formData) {
			// upload data to the server
			this.currentStatus = STATUS_SAVING
			ApiClient.post('/upload/', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then(x => {
				this.uploadedFiles = [].concat(x)
				this.currentStatus = STATUS_SUCCESS
				if (this.$route.params.app === 'irp' && this.$route.params.collection === 'users') {
					// sent get request to this backend route to display new file in "liste" if currently I upload file in /irp/users/...
					this.$store.dispatch('irp/irpUserFiles/load', { all: true })
				}
				this.$store.dispatch(`${this.$route.params.app}/${this.$route.params.collection}/loadDetail`, this.$route.params.primaryKey)
				this.activeTab = 0
			}).catch(err => {
				this.$store.dispatch('addNotification', { title: this.$t('error'), message: err.response.data.detail, error: true })
				this.uploadError = err.response
				this.currentStatus = STATUS_FAILED
			})
		},
		filesChange (fieldName, fileList) {
			// handle file changes
			const formData = new FormData()

			if (!fileList.length) return

			// append the files to FormData
			Array
				.from(Array(fileList.length).keys())
				.map(x => {
					formData.append(fieldName, fileList[x], fileList[x].name)
				})
			formData.append('object_id', this.objectId)
			formData.append('content_type', this.contentType)
			// save it
			this.save(formData)
		}
	},
	mounted () {
		this.itemIds = this.value || []
		this.reset()
	}
}

</script>
