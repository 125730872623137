import Crud from '../../crud'

let crud = new Crud('accounts/feedbacks')

export default {
	namespaced: true,
	state: () => ({
		...crud.state
	}),
	getters: {
		...crud.getters,
		canDeleteMulti: () => false,
		customAddView: () => 'accounts/AddFeedback',
		fields () {
			return [
				{ text: 'Erstellt am', value: 'created', format: 'DateTime', width: 150, readOnly: true },
				{ text: 'Benutzer', width: 250, value: 'user_email', hideCreate: true, hideEdit: false, readOnly: true, order: 'user__email' },
				{ text: 'Feedback', width: -1, value: 'text', type: 'textarea' },
				{ text: 'Erledigt', width: 150, value: 'done', format: 'BooleanView', type: 'boolean' }
			]
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions
	}
}
