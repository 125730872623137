
export default {
	namespaced: true,
	state: () => ({
		all: [],
		detailItem: null
	}),
	getters: {
		all: state => state.all,
		detailItem: state => state.detailItem,
		getById: (state) => (id) => { return state.all.find(o => o.id === parseInt(id)) },
		fields (state, getters, rootState, rootGetters) {
			return rootGetters['accounts/responsibles/fields']
		},
		title (state, getters, rootState, rootGetters) {
			return rootGetters['accounts/responsibles/title']
		},
		pagination (state, getters, rootState, rootGetters) {
			return rootGetters['accounts/responsibles/pagination']
		}
	},
	mutations: {
		store (state, objects) {
			state.all = objects
		},
		storeDetail (state, item) {
			state.detailItem = item
		}
	},
	actions: {
		add ({ dispatch, rootGetters }, payload) {
			payload.content_type = rootGetters['accounts/contenttypes/getByName']('patzu.hospital').id
			return dispatch('accounts/responsibles/add', payload, { root: true })
		},

		async load ({ commit, dispatch, rootGetters }, payload) {
			if (payload.query === undefined) {
				payload.query = {}
			}
			payload.query.content_type = rootGetters['accounts/contenttypes/getByName']('patzu.hospital').id
			await dispatch('accounts/responsibles/load', payload, { root: true })
			commit('store', rootGetters['accounts/responsibles/all'])
		},
		async loadDetail ({ commit, dispatch, rootGetters }, id) {
			commit('storeDetail', null)
			await dispatch('accounts/responsibles/loadDetail', id, { root: true })
			commit('storeDetail', rootGetters['accounts/responsibles/detailItem'])
		},
		async update ({ dispatch }, payload) {
			return dispatch('accounts/responsibles/update', payload, { root: true })
		},
		delete ({ dispatch }, payload) {
			return dispatch('accounts/responsibles/delete', payload, { root: true })
		}
	}
}
