<template>
  <div v-if="users.length" class="bg-neutral-100 dark:bg-neutral-900 p-2 mb-2 border-2 border-neutral-300 dark:border-neutral-700">
    <div class="flex items-center space-x-2 p-1">
      <div class="font-bold select-none">List of Users</div>
    </div>
    <div class="my-2 w-full bg-white dark:bg-neutral-900">
      <div class="w-full overflow-auto">
        <table class="table-fixed">
          <thead class="min-w-full divide-y divide-neutral-200 bg-neutral-100 dark:divide-neutral-800 dark:bg-neutral-900">
            <tr>
              <th class="whitespace-nowrap px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-neutral-500" style="width: 60px">
                <div class="flex items-center">
                  <span class="has-tooltip truncate" data-original-title="null">{{$t('ID')}}</span>
                </div>
              </th>
              <th class="whitespace-nowrap px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-neutral-500" style="width: 300px">
                <div class="flex items-center">
                  <span class="has-tooltip truncate" data-original-title="null">{{$t('Email')}}</span>
                </div>
              </th>
              <th class="whitespace-nowrap px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-neutral-500" style="width: 100%">
                <div class="flex items-center">
                  <span class="has-tooltip truncate" data-original-title="null">{{$t('Name')}}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-neutral-200 bg-white dark:divide-neutral-800 dark:bg-neutral-800 dark:text-neutral-100">
            <tr v-for="(user, index) in users" :key="index" :class="index % 2 === 0 ? '' : 'bg-neutral-50 dark:bg-neutral-900'">
              <td class="whitespace-nowrap">
                <div class="has-tooltip truncate" data-original-title="null">{{ user.id }}</div>
              </td>
              <td class="whitespace-nowrap">
                <div class="has-tooltip truncate" data-original-title="null">{{ user.email }}</div>
              </td>
              <td class="whitespace-nowrap">
                <div class="has-tooltip truncate" data-original-title="null">{{ user.first_name }} {{ user.last_name }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	name: 'UserListComponent',
	props: {
		users: {
			type: Array,
			required: true
		}
	}
}
</script>

  <style scoped>
  /* Add any additional styles you may need */
  </style>
