import Crud from '../../crud'

let crud = new Crud('patzu/measures')

export default {
	namespaced: true,
	state: () => ({
		...crud.state
	}),
	getters: {
		...crud.getters,
		hasHistory: () => true,
		queryGet: () => { return { byPermissions: 0 } },
		contentType: (state, getters, rootState, rootGetters) => rootGetters['accounts/contenttypes/getByName']('patzu.measure').id,
		filters (state, getters, rootState, rootGetters) {
			return [
				{
					name: 'Kategorien',
					options: rootGetters['patzu/hospitals/categories'],
					field: 'category'
				},
				{
					name: 'Jahre',
					options: rootGetters.years.map(y => { return { id: y, value: y } }),
					field: 'year'
				}
			]
		},
		fields (state, getters, rootState, rootGetters) {
			return [
				{ text: 'Name', value: 'name', width: -1, showSelect: true },
				{ text: 'Jahr', value: 'year', order: 'start_date', width: 80, hideEdit: true, hideCreate: true },
				{ text: 'Start', value: 'start_date', type: 'date', format: 'Date', width: 120, style: 'w-1/2 pr-2', showSelect: true },
				{ text: 'Ende', value: 'end_date', type: 'date', format: 'Date', width: 120, style: 'w-1/2 pl-2', showSelect: true },
				{ text: 'LS Start', value: 'ls_valid_from', type: 'date', format: 'Date', width: 120, style: 'w-1/2 pr-2', showSelect: false },
				{ text: 'LS Ende', value: 'ls_valid_until', type: 'date', format: 'Date', width: 120, style: 'w-1/2 pl-2', showSelect: false },
				{
					text: 'Typ',
					value: 'typ',
					type: 'select',
					width: 220,
					options: [
						{ id: 1, value: 'ANQ Messung' },
						{ id: 2, value: 'Zwischenbefragung (ANQ Messung)' },
						{ id: 3, value: 'Zusatzbefragung' }
					]
				},
				{
					text: 'Kategorie',
					value: 'category',
					type: 'select',
					width: 220,
					options: [
						{ id: 1, value: 'Psychiatrie' },
						{ id: 2, value: 'Rehabilitation' },
						{ id: 3, value: 'Akutsomatik Erwachsene' },
						{ id: 4, value: 'Akutsomatik Eltern' },
						{ id: 5, value: 'Kinder und Jugendpsychiatrie' }
					]
				},
				{
					text: 'Spital',
					value: 'hospitals',
					type: 'rel_select',
					isMulti: true,
					getterPath: 'patzu/hospitals',
					customFields: rootGetters['patzu/hospitals/fields'].filter(h => h.showSelect || h.value === 'is_active'),
					hideList: true,
					query: (item) => { return item ? { category: item.category, state: 1 } : null },
					initQuery: (item) => { return item ? { measures__id: item.id, includeDeleted: '1' } : null }
				},
				{ text: 'Teilnehmer', value: 'hospitals', width: 100, hideEdit: true, hideCreate: true, renderItem: (value) => value.length }
			]
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions
	}
}
