import ApiClient from '@/assets/js/ApiClient.js'
import Crud from '../../crud'
import { i18n } from '@/main.js'

let crud = new Crud('filemanager/files')

export default {
	namespaced: true,
	state: () => ({
		...crud.state
	}),
	getters: {
		...crud.getters,
		customAddView: () => 'files/AddFile',
		queryGet: () => { return { byPermissions: 0 } },
		actions () {
			return [
				{ label: 'Lösche Fehlerhafte Dateien', action: 'cleanUpFiles' }
			]
		},
		detailActions () {
			return [
				{ label: 'Herunterladen', action: 'downloadFile' }
			]
		},
		filters (state, getters, rootState, rootGetters) {
			const filterCtOptions = rootGetters['accounts/contenttypes/appPermissions'].map(ct => {
				return {
					id: ct.id, value: i18n.t(`${ct.app_label}.${ct.model}s`), app: ct.app_label, model: ct.model
				}
			})
			return [
				{
					name: 'Kategorien',
					options: filterCtOptions,
					field: 'content_type_ids'
				}
			]
		},
		fieldsDashboard (state, getters, rootState, rootGetters) {
			const getCCById = rootGetters['accounts/contenttypes/getById']
			return [
				{ text: i18n.t('file_name'), value: 'name', width: 80, showSelect: true },
				{
					text: i18n.t('typ'),
					value: 'extension',
					width: 15,
					showSelect: true,
					readOnly: true
				},
				{
					text: i18n.t('object'),
					format: 'Link',
					value: 'content_object.name',
					order: 'object_id',
					readOnly: true,
					hideCreate: true,
					width: 80,
					getLink: (item) => { const cc = getCCById(item.content_type); return `/${cc.app_label}/${cc.model}s/${item.content_object.id}` },
					getDescription: (item) => item.content_object.name,
					showSelect: true
				}
			]
		},
		fields (state, getters, rootState, rootGetters) {
			const getCCById = rootGetters['accounts/contenttypes/getById']
			return [
				{ text: i18n.t('name'), value: 'name', width: -1, showSelect: true },
				{
					text: i18n.t('typ'),
					value: 'extension',
					width: 35,
					showSelect: true,
					readOnly: true
				},
				{
					text: i18n.t('object'),
					format: 'Link',
					value: 'content_object.name',
					order: 'object_id',
					readOnly: true,
					hideCreate: true,
					width: 350,
					getLink: (item) => { const cc = getCCById(item.content_type); return `/${cc.app_label}/${cc.model}s/${item.content_object.id}` },
					getDescription: (item) => item.content_object.name,
					showSelect: true
				},
				{ text: i18n.t('created_by'), value: 'created_by.email', readOnly: true, hideCreate: true, width: 200 },
				{ text: i18n.t('created'), value: 'created', format: 'DateTime', readOnly: true, hideCreate: true, width: 160 },
				{ text: i18n.t('modified'), value: 'modified', format: 'DateTime', readOnly: true, hideCreate: true, width: 160, showSelect: true }
			]
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions,
		async cleanUpFiles ({ state, dispatch }) {
			dispatch('startLoading', null, { root: true })
			await ApiClient.delete(`${state.endpoint}/clean_up/`)
			dispatch('addNotification', { title: 'Gelöscht', message: 'Dateien wurden aufgeräumt' }, { root: true })
			dispatch('endLoading', null, { root: true })
		},
		async downloadFile ({ getters, dispatch }, primaryKey) {
			await dispatch('loadDetail', primaryKey)
			let item = getters.detailItem
			ApiClient.get(`/filemanager/files/${item.id}/download/?byPermissions=0`, { responseType: 'blob' }).then(response => {
				let fileURL = window.URL.createObjectURL(new Blob([response.data]))
				let fileLink = document.createElement('a')
				fileLink.href = fileURL
				fileLink.setAttribute('download', `${item.name}.${item.extension}`)
				document.body.appendChild(fileLink)
				fileLink.click()
			}).catch(err => {
				console.log(err)
			})
		}
	}
}
