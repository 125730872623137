import Crud from '../../crud'

let crud = new Crud('accounts/app_permissions')

export default {
	namespaced: true,
	state: () => ({
		...crud.state
	}),
	getters: {
		...crud.getters,
		getByType: (state) => (typ) => { return state.all.filter(permission => permission.content_type === typ) },
		getByUser: (state) => (user) => { return state.all.filter(permission => permission.user === user) },
		getIdsByContentType: (state, getters, rootState, rootGetters) => (name) => {
			const contentType = rootGetters['accounts/contenttypes/getByName'](name)
			if (!contentType) {
				return []
			}
			return state.all.filter(permission => permission.content_type === contentType.id).map(p => p.id)
		},
		hasAccess: (state, getters, rootState, rootGetters) => (name, adminRole = false) => {
			const contentType = rootGetters['accounts/contenttypes/getByName'](name)

			if (!contentType) {
				return false
			}
			if (adminRole) {
				return state.all.find(permission => permission.content_type === contentType.id && permission.role === 1) !== undefined
			}
			return state.all.find(permission => permission.content_type === contentType.id) !== undefined
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions
	}
}
