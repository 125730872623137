<template>
  <div>
    <div v-if="isLoading" class="top-40 left-52 z-50 fixed w-full h-full cursor-wait backdrop-filter backdrop-blur-sm"></div>
    <div v-if="openUpdateEvalChildModal" class="mpointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center p-12 z-50">
      <div class="absolute w-full h-full bg-neutral-900 opacity-50"></div>
      <div class="flex flex-col bg-white w-1/3 mx-auto rounded shadow-lg z-50 overflow-hidden">
        <div class="w-full flex items-center p-4 bg-white">
          <h1 class="flex-grow text-lg font-bold">
            Confirmation
          </h1>
        </div>
        <div class="p-6 text-center">
          <p class="text-base">
            Do you want to update
            '{{ getSelectedEvaluation === 'all' ? 'ALL EVALUATIONS': `Evaluation ${getSelectedEvaluation}` }}' with selected charts?
          </p>
          <p>PLEASE WRITE 'OK' TO UPDATE</p>
          <input v-model="inputTextFromModal" type="text" />
        </div>
        <div class="w-full flex space-x-4 px-6 py-4 bg-gray-100">
          <button class="cancel w-full py-2 px-4 bg-gray-300 rounded" @click="closeModal">
            CANCEL
          </button>
          <button class="primary w-full py-2 px-4 bg-blue-500 text-white rounded" :disabled="inputTextFromModal!=='OK'" @click="updateEvaluationChild">
            OK
          </button>
        </div>
      </div>
    </div>
    <div v-if="isPathBenchmark && !isStaff" class="text-center text-xl mt-20">
      {{ $t('Ungültige Berechtigungen') }}<br />
      {{ $t('Bitte kontaktieren Sie ihren Administrator.') }}
    </div>
    <div v-else>
      <div v-if="item" class="relative flex flex-row w-full">
        <div class="flex w-full">
          <div class="flex-grow">
            <template v-if="isInEvaluationOnly">
              <section class="flex flex-row justify-between">
                <div v-if="canCreateEvalChildEvaluation">
                  <evaluation-details
                    v-if="(fspMeasureOnly || anqMeasureOnly) && isStaff"
                    :selected-eval="selectedEval"
                    :update="true"
                    :open-update-eval-child-modal.sync="openUpdateEvalChildModal"
                  />
                </div>
                <div
                  v-if="!isNewItem"
                  class="h-full mt-2 mr-0 ml-auto border-2 rounded-md text-black"
                  :class="{
                    'bg-green-800': isDarkMode && item.is_parent_evaluation,
                    'bg-red-300': isDarkMode && !item.is_parent_evaluation,
                    'bg-green-300': !isDarkMode && item.is_parent_evaluation,
                    'bg-red-300': !isDarkMode && !item.is_parent_evaluation
                  }"
                >
                  <a v-if="!item.is_parent_evaluation && checkIfHasRights" :href="parentEvaluationHref" class="button-label" @click="goToParentEvaluation">
                    Go to Parent Evaluation
                  </a>
                </div>
              </section>
            </template>
            <component
              :is="customAddView.split('/')[customAddView.split('/').length - 1]"
              v-if="hasCustomAddView"
              :item="editValues"
              :chart-user-array="chartUserArray"
            />
            <component
              :is="customEditView.split('/')[customEditView.split('/').length - 1]"
              v-else-if="hasCustomEditView"
              :item="item"
              :chart-user-array="chartUserArray"
            />
            <!-- do I need to put here chartUserArray as props??? -->
            <!-- do I need to put here chartUserArray as props??? -->
            <div v-else>
              <template v-if="isInMeasureOnly">
                <section class="flex flex-row justify-between">
                  <div>
                    <label v-tip="'measures'" class="mt-2 text-lg">{{ $t('importSettings') }}</label>
                    <input
                      id="json"
                      ref="file"
                      type="file"
                      name="json"
                      class="hidden"
                      @change="loadJSON($event)"
                    />
                    <p v-if="fileName" class="uk-display-inline-block uk-width-1-3">
                      {{ fileName }}
                    </p>
                    <p v-else class="uk-display-inline-block uk-width-1-2">
                      {{ $t('NoChosenFile') }}
                    </p>
                    <button class="" type="button" tabindex="-1" @click="$refs.file.click()">
                      {{ $t('chooseFile') }}
                    </button>
                  </div>
                  <div
                    v-if="!isNewItem"
                    class="h-full mt-2 mr-20 border-2 rounded-md border-black text-black"
                    :class="{
                      'bg-green-800': isDarkMode && item.is_parent_measure,
                      'bg-red-300': isDarkMode && !item.is_parent_measure,
                      'bg-green-300': !isDarkMode && item.is_parent_measure,
                      'bg-red-300': !isDarkMode && !item.is_parent_measure
                    }"
                  >
                    <label v-if="item.is_parent_measure" class="m-2 text-lg">{{ item.is_parent_measure ? $t('Parent measure') : $t('Go to parent measure') }}</label>
                    <a v-else :href="parentMeasureHref" class="button-label" @click="goToParent">
                      {{ item.is_parent_measure ? t('Parent measure') : 'Go to parent measure' }}
                    </a>
                  </div>
                </section>
              </template>
              <!-- here table with route /irp/users/1000 -->
              <v-form
                :is-new-item="isNewItem"
                :values="item"
                :fields="fields"
                :can-delete="canDelete"
                :condition="addCondition"
              />
              <template v-if="isInMeasureOnly">
                <button v-tip="'measures'" class="primary mt-8 mb-6 w-full" @click="download_measure()">
                  {{ $t('download-settings') }}
                </button>
              </template>
              <UserListComponent v-if="isGroupView" :users="item.users" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import VForm from '@/components/v-form.vue'
import { isEqual, omit, sortBy } from 'lodash'
import UserListComponent from '@/components/UserListComponent.vue'
import VSelect from '@/components/v-select'
import EvaluationDetails from '../components/EvaluationDetails.vue'
import ApiClient from '@/assets/js/ApiClient.js'

export default {
	name: 'Item',
	components: {
		'v-form': VForm,
		UserListComponent: UserListComponent,
		'v-select': VSelect,
		EvaluationDetails
	},
	data () {
		return {
			newItem: {},
			fileName: '',
			userExistInDb: false,
			selectedEval: this.getSelectedEvaluation,
			openUpdateEvalChildModal: false,
			inputTextFromModal: '',
			isDarkMode: false
		}
	},
	computed: {
		...mapGetters({
			isStaff: 'isStaff',
			editValues: 'editValues',
			isLoading: 'isLoading',
			currentEvaluationCharts: 'results/evaluations/charts',
			selectedChartsIdsForChildren: 'results/evaluations/selectedChartsIdsForChildren',
			getSelectedEvaluation: 'results/evaluationStore/getSelectedEvaluation',
			getSelectedChartIdsEvaluationScope: 'results/evaluationStore/getSelectedChartIdsEvaluationScope',
			canCreateEvalChildEvaluation: 'results/evaluations/canCreateEvalChildEvaluation',
			childrenIds: 'results/evaluations/childrenIds',
			user: 'user',
			fspMeasureOnly: 'results/evaluations/fspMeasureOnly',
			anqMeasureOnly: 'results/evaluations/anqMeasureOnly',
			// getEvaluationById: 'results/evaluations/getById',
		}),
		checkIfHasRights() {
			// console.log('this.item?.parent_evaluation_id', this.item?.parent_evaluation_id)
			// const parent = this.getEvaluationById(this.item?.parent_evaluation_id)

			// console.log('parent', parent)
			// if (!this.isStaff && !(this.item?.id in parent?.view_access_admins) && !(this.item?.id in parent?.view_access_users)) {
			// 	return false
			// }
			if (!this.isStaff) {
				return false
			}
			return true
		},
		evaluationOptions() {
			if (this.childrenIds.length === 0) {
				return [{ id: 'all', value: 'All children evaluations' }]
			}
			return [
				{ id: 'all', value: 'All children evaluations' },
				...this.childrenIds.map(id => ({
					id: id,
					value: `Evaluation ${id}` // for now only the id
				}))
			]
		},
		isStaff () {
			return this.user.is_staff === true
		},
		isGroupView () {
			return this.$route.path.includes('/apps/groups/')
		},
		parentMeasureHref () {
			return `${window.location.origin}/results/measures/${this.item.parent_measure_id}`
		},
		parentEvaluationHref () {
			return `${window.location.origin}/results/evaluations/${this.item.parent_evaluation_id}`
		},
		isInMeasureOnly () {
			return this.$route.path.startsWith('/results/measures/')
		},
		isInEvaluationOnly () {
			return this.$route.path.startsWith('/results/evaluations/')
		},
		canDelete () {
			const canDelete = this.$store.getters[`${this.$route.params.app}/${this.$route.params.collection}/canDelete`]
			return canDelete === undefined ? true : canDelete(this.$route.params.primaryKey)
		},
		fields () {
			return this.$store.getters[`${this.$route.params.app}/${this.$route.params.collection}/fields`]
		},
		addCondition () {
			if (this.$route.params.collection === 'hospitals') {
				return { path: 'category', id: 13 }
			}
			return null
		},
		item () {
			if (this.isNewItem) {
				let tmp = this.newItem
				this.fields.filter(f => f.default !== undefined).forEach(field => {
					tmp[field.value] = field.default
				})
				return tmp
			} else if (this.$route.params.app.includes('irp') && this.$route.params.collection.includes('users')) {
				return this.irpUserAndFiles()
			} else {
				return this.$store.getters[`${this.$route.params.app}/${this.$route.params.collection}/detailItem`]
			}
		},
		chartUserArray () {
			return this.$store.getters[`${this.$route.params.app}/${this.$route.params.collection}/detailUserCharts`]
		},
		isNewItem () {
			return this.$route.params.primaryKey === '+'
		},
		customAddView () {
			return this.$store.getters[`${this.$route.params.app}/${this.$route.params.collection}/customAddView`]
		},
		hasCustomAddView () {
			return this.isNewItem && this.customAddView !== undefined
		},
		customEditView () {
			return this.$store.getters[`${this.$route.params.app}/${this.$route.params.collection}/customEditView`]
		},
		hasCustomEditView () {
			return !this.isNewItem && this.customEditView !== undefined
		},
		rootPath () {
			return this.$route.path.split('/').filter(v => v !== '')[0]
		},
		isPathBenchmark () {
			return this.$route.path.includes('/results/benchmarks')
		}
	},
	methods: {
		...mapActions({
			addNotification: 'addNotification',
			setSelectedEvaluation: 'results/evaluationStore/setSelectedEvaluation',
		}),
		goToParent () {
			if (this.item.parent_measure_id) {
				this.$router.push({ path: `/results/measures/${this.item.parent_measure_id}` })
			}
		},
		goToParentEvaluation () {
			if (this.item.parent_evaluation_id) {
				this.$router.push({ path: `/results/measures/${this.item.parent_evaluation_id}` })
			}
		},
		async fetchTooltipData (category) {
			try {
				const response = await fetch(`https://docs-tooltips.sos-ch-dk-2.exo.io/${category}-v0.json`)
				this.$store.commit(`results/${category}/storeToolTip`, await response.json())
			} catch (error) {
				console.error('Error fetching tooltip data:', error)
			}
		},
		async openModal () {
			this.openUpdateEvalChildModal = true
		},
		async closeModal () {
			this.openUpdateEvalChildModal = false
		},
		async updateEvaluationChild() {
			if (this.getSelectedEvaluation === 'All Evaluations' || this.getSelectedEvaluation === 'all') {
				for (const childrenId of this.childrenIds) {
					try {
						const response = await ApiClient.put(
							`results/evaluations/${childrenId}/update_charts_for_child/`,
							this.getSelectedChartIdsEvaluationScope
						)
						console.log('API call successful:', response)
					} catch (error) {
						console.error('Error (continuing):', error)
					}
				}
			} else {
				await new Promise((resolve) => {
					ApiClient.put(`results/evaluations/${this.getSelectedEvaluation}/update_charts_for_child/`, this.getSelectedChartIdsEvaluationScope)
						.then((res) => {
							return res
						})
						.then(response => {
							console.log('API calls successful:', response)
							resolve(response)
						})
						.catch(error => {
							console.error('Error in API calls (continuing anyway):', error)
							resolve()
						})
				})
			}

			this.inputTextFromModal = ''
			this.openUpdateEvalChildModal = false
		},
		async createUserForFiles () {
			await this.$store.dispatch(`${this.$route.params.app}/irpUserFiles/add`, {
				name: 'irpUserFiles',
				user: this.$route.params.primaryKey
			})
		},
		irpUserAndFiles () {
			// const irpUserAndFilesArray = []
			const irpUserFilesArray = []
			const irpUserData = this.$store.getters[`${this.$route.params.app}/${this.$route.params.collection}/detailItem`]
			const irpUserFiles = this.$store.getters[`${this.$route.params.app}/irpUserFiles/all`]

			if (irpUserData && irpUserFiles) {
				irpUserFiles.forEach((userData) => {
					if (irpUserData.id === userData.user) {
						this.userExistInDb = true

						irpUserData.user_file_id = userData.id

						userData.files.forEach((file) => {
							irpUserFilesArray.push(file)
						})
					}
				})

				if (!this.userExistInDb) {
					this.createUserForFiles()
				}

				irpUserData.files = irpUserFilesArray
			}

			return irpUserData
		},
		download_measure () {
			const serialize = JSON.stringify(this.editValues)
			const fileURL = window.URL.createObjectURL(new Blob([serialize]))
			const fileLink = document.createElement('a')
			fileLink.href = fileURL
			const evalID = String(this.editValues.id)
			fileLink.setAttribute('download', 'settings_measure_' + evalID + '.json')
			document.body.appendChild(fileLink)
			fileLink.click()
		},
		loadJSON (e) {
			let vm = this
			if (window.FileReader) {
				if (e.target.files[0]) {
					let reader = new FileReader()
					this.fileName = e.target.files[0].name
					reader.readAsText(e.target.files[0])
					// Handle errors load
					reader.onload = function (event) {
						let json = event.target.result
						vm.uploadJSON(json)
					}
					reader.onerror = function (evt) {
						if (evt.target.error.name === 'NotReadableError') {
							alert("Canno't .read file !")
						}
					}
				} else {
					this.fileName = ''
				}
			} else {
				alert('FileReader are not supported in this browser.')
			}
		},
		uploadJSON (json) {
			this.$store.commit('setEditValues', JSON.parse(json))
			this.$store.commit(`${this.$route.params.app}/${this.$route.params.collection}/storeDetail`, JSON.parse(json))
		},
		registerComponents () {
			if (this.customAddView) {
				const component = import('@/views/' + this.customAddView + '.vue')
				const tmpName = this.customAddView.split('/')
				const componentName = tmpName[tmpName.length - 1]
				Vue.component(componentName, () => ({
					component: component
				}))
			}
			if (this.customEditView) {
				const component = import('@/views/' + this.customEditView + '.vue')
				const tmpName = this.customEditView.split('/')
				const componentName = tmpName[tmpName.length - 1]
				Vue.component(componentName, () => ({
					component: component
				}))
			}
		},
		getObjectDiff (obj1, obj2) {
			const diff = Object.keys(obj1).reduce((result, key) => {
				// eslint-disable-next-line no-prototype-builtins
				if (!obj2.hasOwnProperty(key)) {
					result.push(key)
				} else if (isEqual(obj1[key], obj2[key])) {
					const resultKeyIndex = result.indexOf(key)
					result.splice(resultKeyIndex, 1)
				}
				return result
			}, Object.keys(obj2))

			return diff
		}
	},
	async mounted () {
		if (!this.isNewItem) {
			if (this.$route.params.collection.includes('evaluations')) {
				await this.$store.dispatch(`${this.$route.params.app}/${this.$route.params.collection}/loadDetailEvaluation`, this.$route.params.primaryKey)
			} else if (this.$route.params.app.includes('irp') && this.$route.params.collection.includes('users')) {
				await this.$store.dispatch('irp/irpUserFiles/load', { all: true })
				await this.$store.dispatch(`${this.$route.params.app}/${this.$route.params.collection}/loadDetail`, this.$route.params.primaryKey)
			} else {
				await this.$store.dispatch(`${this.$route.params.app}/${this.$route.params.collection}/loadDetail`, this.$route.params.primaryKey)
			}
			this.$store.commit('setEditValues', this.item)
			this.$store.commit('setUserCharts', this.chartUserArray)
		}
		if (this.isInMeasureOnly) {
			this.fetchTooltipData('measures')
		}
		this.isDarkMode = document.documentElement.classList.contains('dark')
	},
	created () {
		this.registerComponents()
	},
	beforeRouteLeave (to, from, next) {
		if (!this.item || !this.editValues) {
			next()
			return
		}

		// Get Array Values and sort by integer
		let arrayValuesKeys = []
		Object.keys(this.item).forEach(key => {
			if (Array.isArray(this.item[key])) {
				arrayValuesKeys.push(key)
			}
		})
		let isItemEqual = isEqual(omit(this.item, arrayValuesKeys), omit(this.editValues, arrayValuesKeys))
		// Check ArrayKeys
		for (const key of arrayValuesKeys) {
			isItemEqual = isEqual(sortBy(this.item[key]), sortBy(this.editValues[key]))
			if (!isItemEqual) {
				break
			}
		}

		// Speziallfall Permissions
		if (this.editValues && this.editValues.app_permissions !== undefined) {
			isItemEqual = true
			const appPermissions = this.item.app_permissions.map(id => {
				const perm = this.$store.getters['accounts/appPermissions/getById'](id)
				return perm ? { id: perm.id, content_type: perm.content_type, object_id: perm.object_id, role: perm.role } : {}
			})
			isItemEqual = isItemEqual && this.editValues.app_permissions.length === appPermissions.length
			for (const perm of this.editValues.app_permissions) {
				const perm2 = appPermissions.find(p => p.id === perm.id)
				if (perm2) {
					isItemEqual = isItemEqual && perm.content_type === perm2.content_type && perm.object_id === perm2.object_id && perm.role === perm2.role
				}
			}
		}
		if (this.editValues === null || isItemEqual || this.isNewItem) {
			this.$store.commit('setEditValues', null)
			this.$store.commit('setUserCharts', null)
			next()
		} else {
			const params = {
				title: 'warning',
				text: 'warning-text',
				type: 'warning',
				types: 'only',
				onConfirm: () => {
					this.$store.commit('setEditValues', null)
					this.$store.commit('setUserCharts', null)
					next()
				}
			}
			this.$confirmModal.show(params)
		}
	}
}
</script>

<style scoped>
.button-label {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #fff;
  background-color: #f56565; /* Tailwind's red-500 */
  border-radius: 0.375rem; /* Tailwind's rounded-md */
  cursor: pointer;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.button-label:hover {
  background-color: #e53e3e; /* Tailwind's red-600 */
  transform: translateY(-2px);
}

.button-label:active {
  background-color: #c53030; /* Tailwind's red-700 */
  transform: translateY(0);
}
</style>
