import Crud from '../../crud'

let crud = new Crud('mailer/templates')

export default {
	namespaced: true,
	state: () => ({
		...crud.state
	}),
	getters: {
		...crud.getters,
		hasHistory: () => true,
		contentType: (state, getters, rootState, rootGetters) => rootGetters['accounts/contenttypes/getByName']('mailer.mailtemplate').id,
		getDefaultTemplate: (state) => state.all.find(t => t.is_default === true),
		canDelete: (state) => (id) => {
			const item = state.all.find(o => o.id === parseInt(id))
			return item !== undefined ? !item.is_default && !item.is_pw_forgot && !item.is_demo : false
		},
		canClone: (state, getters, rootState, rootGetters) => (item) => {
			return item !== null && rootGetters.user.is_staff
		},
		fields (state, getters, rootState, rootGetters) {
			const user = rootGetters.user
			return [
				{ text: 'Beschreibung', value: 'name', width: -1 },
				{ text: 'Betreff', values: ['subject_de', 'subject_fr', 'subject_it'], type: 'component', componentName: 'mailer/Subject', hideList: true },
				{ text: 'Text', values: ['body_de', 'body_fr', 'body_it'], type: 'component', componentName: 'mailer/Body', hideList: true },
				{ text: 'Sender E-Mail', value: 'from_email', width: -1 },
				{ text: 'Passwort vergessen Template?', value: 'is_pw_forgot', format: 'BooleanView', type: 'boolean', width: 100, hideList: true, hideEdit: user.id !== 1, hideCreate: true },
				{ text: 'Demo Einladungslink', value: 'is_demo', format: 'BooleanView', type: 'boolean', width: 100, hideList: true, hideEdit: user.id !== 1, hideCreate: true },
				{ text: 'Standard Einladungs Email', value: 'is_default', format: 'BooleanView', type: 'boolean', width: 100, hideList: true, hideEdit: user.id !== 1, hideCreate: true },
				{ text: 'Geändert', format: 'Date', value: 'modified', width: -1 }
			]
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions
	}
}
