import Crud from '../../crud'

let crud = new Crud('accounts/demo_users')

export default {
	namespaced: true,
	state: () => ({
		...crud.state
	}),
	getters: {
		...crud.getters,
		canDeleteMulti: () => false,
		disableAdd: () => true,
		fields () {
			return [
				{ text: 'Erstellt am', value: 'created', format: 'DateTime', width: 150, readOnly: true },
				{ text: 'Email', width: 250, value: 'email', readOnly: true },
				{ text: 'Link Count', width: -1, value: 'link_count', readOnly: true }
			]
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions
	}
}
