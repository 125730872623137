<template>
  <!--Please input URL to Application-->
  <form method="post" action="https://fed.hin.ch/saml/2.0/idp/" @submit="dologin">
    <button type="submit" class="rounded-md flex items-center justify-center bg-[#f39900] text-white p-4 w-full">
      <i class="border-r-2 border-gray border-opacity-30 pr-2 mr-2"><img src="../assets/images/hin_logo.png"/></i>
      <span class="login">Login with HIN</span>
      <input style="display:none;" type="submit"/>
      <input name="SAMLRequest" :value=samlRequest type="hidden"/>
    </button>
  </form>
</template>

<script>
// import ApiClient from '../assets/js/ApiClient.js'
export default {
	props: ['samlRequest'],
	data () {
		return {
			db: null
		}
	},
	methods: {
		openDatabase () {
			return new Promise((resolve, reject) => {
				const dbName = 'loginData'
				const dbVersion = 1
				const request = indexedDB.open(dbName, dbVersion)

				request.onupgradeneeded = (event) => {
					const db = event.target.result
					// Create object store if it doesn't exist
					db.createObjectStore('login', { keyPath: 'id' })
				}

				request.onerror = (event) => {
					console.error('Error opening IndexedDB:', event.target.error)
					reject(event.target.error)
				}

				request.onsuccess = (event) => {
					// console.log('IndexedDB opened successfully')
					this.db = event.target.result
					resolve(this.db)
				}
			})
		},
		addOrUpdateData (data) {
			return new Promise((resolve, reject) => {
				if (!this.db) {
					// eslint-disable-next-line prefer-promise-reject-errors
					reject('IndexedDB is not yet opened.')
					return
				}

				// Start a transaction and access the object store
				const transaction = this.db.transaction(['login'], 'readwrite')
				const store = transaction.objectStore('login')

				// Check if 'loginPath' exists before updating
				const getRequest = store.get('loginPath')

				getRequest.onsuccess = () => {
					const existingPath = getRequest.result

					if (existingPath) {
						// Update existing path if found
						existingPath.path = data
						store.put(existingPath)
					} else {
						// Create new entry if 'loginPath' doesn't exist
						store.add({ id: 'loginPath', path: data })
					}

					transaction.oncomplete = () => {
						console.log('Login path stored successfully')
						resolve()
					}

					transaction.onerror = (event) => {
						console.error('Error storing login path:', event.target.error)
						reject(event.target.error)
					}
				}

				getRequest.onerror = (event) => {
					console.error('Error retrieving login path:', event.target.error)
					reject(event.target.error)
				}
			})
		},
		fetchData () {
			const transaction = this.db.transaction(['myObjectStore'], 'readwrite')
			const objectStore = transaction.objectStore('myObjectStore')
			const getAllRequest = objectStore.getAll()

			getAllRequest.onsuccess = (event) => {
				console.log('Data from database', event.target.result)
			}

			getAllRequest.onerror = () => {
				console.error('Unable to retrieve data from IndexedDB')
			}
		},
		async dologin (event) {
			try {
				await this.openDatabase()

				await this.addOrUpdateData(window.location.pathname)

				this.login(event)
			} catch (error) {
				console.error('Error during login process:', error)
			}
		},
		async login (elem) {
			elem.preventDefault()
			this.$router.replace('/')
			const form = document.createElement('form')
			form.method = 'POST'
			form.action = 'https://fed.hin.ch/saml/2.0/idp/'
			const input = document.createElement('input')
			input.type = 'hidden'
			input.name = 'SAMLRequest'
			input.value = this.samlRequest
			form.appendChild(input)
			document.body.appendChild(form)
			form.submit()
		}
	},
	async mounted () {
		await this.openDatabase()
	}
}

</script>
