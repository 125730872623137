import Crud from '../../crud'

let crud = new Crud('apps/groups')

export default {
	namespaced: true,
	state: () => ({
		...crud.state
	}),
	getters: {
		...crud.getters,
		canDeleteMulti: () => false,
		hasHistory: () => true,
		contentType: (state, getters, rootState, rootGetters) => rootGetters['accounts/contenttypes/getByName']('apps.group').id,
		exportActions () {
			return [
				{
					name: 'Grouppenlist',
					path: 'apps/groups/export/'
				}
			]
		},
		queryGet: () => { return { byPermissions: 0 } },
		fields (state, getters, rootState, rootGetters) {
			const apps = rootGetters['apps/apps/all']
			const options = apps.map(app => { return { id: app.id, value: app.name } })

			return [
				{ text: 'App', value: 'app', type: 'select', options: options, width: 200, showSelect: true },
				{ text: 'Name', value: 'name', width: -1, showSelect: true },
				{ text: 'Key', value: 'key', type: 'number', width: 80, showSelect: false },
				{ text: 'Import Key', value: 'import_key', type: 'text', width: 80, showSelect: false },
				{ text: 'Filter', value: 'filter', type: 'component', componentName: 'FilterItem', default: [], hideList: true, help: 'Filter Benchmark. Zeige für diese Gruppe die Benchmarks anhand folgender Filter-Kriterien dar:' },
				{ text: 'Dateien', value: 'files', type: 'component', componentName: 'Files', hideCreate: true, hideList: true, width: 120 }
			]
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions
	}
}
