import Crud from '../../crud'

let crud = new Crud('apps/apps')

export default {
	namespaced: true,
	state: () => ({
		...crud.state
	}),
	getters: {
		...crud.getters,
		hasHistory: () => true,
		canDeleteMulti: () => false,
		contentType: (state, getters, rootState, rootGetters) => rootGetters['accounts/contenttypes/getByName']('apps.app').id,
		fields (state, getters, rootState, rootGetters) {
			const contentTypes = rootGetters['accounts/contenttypes/appPermissions']
			const options = contentTypes.map(c => { return { id: c.id, value: `${c.app_label} - ${c.model}` } })
			return [
				{ text: 'Name', value: 'name', width: -2 },
				{ text: 'Interne App', value: 'internal', format: 'BooleanView', type: 'boolean', width: 80 },
				{ text: 'URL', value: 'url', width: 350, hideEdit: (item) => !item || item.internal === true },
				{ text: 'Pfad', value: 'path', width: 120, hideEdit: (item) => !item || item.internal === false },
				{ text: 'Content Type', value: 'content_type', type: 'select', options: options, width: 140 },
				{
					text: 'Rolle',
					value: 'role',
					type: 'select',
					width: 150,
					options: [
						{ id: -1, value: 'Rolle wählen...' },
						{ id: 1, value: 'Benutzer' },
						{ id: 2, value: 'Administrator' }
					]
				}
			]
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions
	}
}
