import ApiClient from '@/assets/js/ApiClient.js'
import Vue from 'vue'
import dayjs from 'dayjs'
import { uniqBy } from 'lodash'
import { DATE_FORMATS } from '@/constants.js'

let customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
let isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

const groupBy = (array, key) => {
	// Return the end result
	return array?.reduce((result, currentValue) => {
		// If an array already present for key, push it to the array. Else create an array and push the object
		(result[currentValue[key]] = result[currentValue[key]] || []).push(
			currentValue
		)
		// Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
		return result
	}, {}) // empty object is the initial value for result object
}

function getFilteredDataGlobal (measureId, data, filters) {
	let filtered = data
	const filterGrouped = groupBy(filters, 'key')
	Object.keys(filterGrouped).forEach(key => {
		let tmp = {}
		filterGrouped[key].forEach(filter => {
			if (filter.type === 'date_range') {
				if (filter.daysBack) {
					const today = dayjs().format('YYYY-MM-DD')
					const from = dayjs().subtract(filter.daysBack, 'day').format('YYYY-MM-DD')
					tmp[measureId] = filtered[measureId].filter((h) => dayjs(h.attributes[filter.key].toString(), DATE_FORMATS).isBetween(from, today, 'day', '[]'))
				} else {
					const today = dayjs().format('YYYY-MM-DD')
					tmp[measureId] = filtered[measureId].filter((h) => dayjs(h.attributes[filter.key].toString(), DATE_FORMATS).isBetween(filter.from, filter.until || today, 'day', '[]'))
				}
			} else {
				const tmpFiltered = filtered[measureId].filter(h => h.attributes[filter.key] && h.attributes[filter.key].toString() === filter.dataValue.toString())
				if (tmp[measureId]) {
					tmp[measureId] = [...tmp[measureId], ...tmpFiltered]
				} else {
					tmp[measureId] = tmpFiltered
				}
			}
		})
		filtered = tmp
	})
	return filtered
}

function getFilteredData (data, filters) {
	let filtered = data
	const filterGrouped = groupBy(filters, 'key')
	Object.keys(filterGrouped).forEach(key => {
		let tmp = []
		filterGrouped[key].forEach(filter => {
			const measure = filter.measure
			if (filter.type === 'date_range') {
				if (filter.daysBack) {
					const today = dayjs().format('YYYY-MM-DD')
					const from = dayjs().subtract(filter.daysBack, 'day').format('YYYY-MM-DD')
					Object.keys(filtered).forEach(key => {
						let filterData = filtered[key]
						if (!Array.isArray(filterData)) {
							filterData = [filterData]
						}
						tmp = tmp.concat(filterData.filter((h) => dayjs(h.attributes[filter.key].toString(), DATE_FORMATS).isBetween(from, today, 'day', '[]')))
					})
				} else {
					const today = dayjs().format('YYYY-MM-DD')
					Object.keys(filtered).forEach(key => {
						let filterData = filtered[key]
						if (!Array.isArray(filterData)) {
							filterData = [filterData]
						}
						tmp = tmp.concat(filterData.filter((h) => dayjs(h.attributes[filter.key].toString(), DATE_FORMATS).isBetween(filter.from, filter.until || today, 'day', '[]')))
					})
				}
			} else {
				if (filter.global === true) {
					Object.keys(filtered).forEach(key => {
						let filterData = filtered[key]
						if (!Array.isArray(filterData)) {
							filterData = [filterData]
						}
						tmp = tmp.concat(filterData.filter(h => h.attributes[filter.key] && h.attributes[filter.key].toString() === filter.dataValue.toString()))
					})
				} else {
					if (filtered[measure]) {
						tmp = tmp.concat(filtered[measure].filter(h => h.attributes[filter.key] && h.attributes[filter.key].toString() === filter.dataValue.toString()))
					}
				}
			}
		})
		filtered = tmp
	})
	return filtered
}

export default {
	namespaced: true,
	state: () => ({
		threshold: 130000,
		fullData: {},
		resultData: {},
		resultBenchmarkData: {},
		filteredData: {},
		seriesDefault: {},
		loading: 0,
		exceedData: false,
		selectedEvaluation: 'All Evaluations',
		selectedChartIdsEvaluationScope: []
	}),
	getters: {
		getSelectedEvaluation: (state) => state.selectedEvaluation,
		getSelectedChartIdsEvaluationScope: (state) => state.selectedChartIdsEvaluationScope,
		isLoading: (state) => state.loading > 0,
		resultData: (state) => state.resultData,
		exceedData: (state) => state.exceedData,
		filteredDataForChartId (state) {
			return (chartId, filters, resultData) => {
				if (filters === undefined) {
					return Object.values(resultData).flat() || []
				}
				return state.filteredData[chartId] || []
			}
		},
		seriesDefault: (state) => state.seriesDefault,
		languages (state, getters, rootState, rootGetters) {
			let languages = []
			Object.keys(state.seriesDefault).forEach(mId => {
				const l = rootGetters['results/measures/getById'](mId).languages
				languages = [...languages, ...l]
			})

			return [...new Set(languages)]
		},
		languagesString () {
			return [1, 2, 3].map(l => {
				if (l === 2) {
					return 'fr'
				}
				if (l === 3) {
					return 'it'
				}
				return 'de'
			})
		},
		seriesDefaultWithoutText: (state) => (measureId) => {
			if (state.seriesDefault[measureId]) {
				return state.seriesDefault[measureId].filter(v => v.type !== 'text')
			}
		},
		seriesDefaultGrouped: (state, getters) => (measureId) => groupBy(getters.seriesDefaultWithoutText(measureId), 'title'),
		// Benchmark
		hasBenchmark (state) {
			return Object.keys(state.resultBenchmarkData).length > 0
		},
		hasBenchmarkForKeyAndMeasure (state) {
			return (measureId, key) => {
				if (key === undefined) {
					return false
				}
				if (state.resultBenchmarkData[measureId] === undefined) {
					return false
				}
				for (const benchmark of state.resultBenchmarkData[measureId]) {
					if (benchmark.means[key] !== undefined) {
						return true
					}
				}
				return false
			}
		},
		resultBenchmarkForMeasureId (state) {
			return (measureId) => {
				if (state.resultBenchmarkData[measureId]) {
					return state.resultBenchmarkData[measureId]
				} else return []
			}
		},
		resultBenchmarksForMeasureIds (state) {
			return (measureIds) => {
				return measureIds.map(measureId => {
					if (state.resultBenchmarkData[measureId]) {
						return state.resultBenchmarkData[measureId]
					} else {
						return []
					}
				})
			}
		},
		benchmarkNames: (state) => (language) => {
			let tmp = []
			Object.keys(state.resultBenchmarkData).forEach(key => {
				tmp = [...tmp, ...state.resultBenchmarkData[key].map(bm => {
					if (language === 'fr') {
						return bm.name_chart_fr
					} else if (language === 'it') {
						return bm.name_chart_it
					}
					return bm.name_chart
				})]
			})
			tmp = [...new Set(tmp)]
			return tmp
		}
	},
	mutations: {
		reset (state) {
			state.fullData = {}
			state.resultData = {}
			state.resultBenchmarkData = {}
			state.filteredData = {}
		},
		setSelectedEvaluation (state, value) {
			state.selectedEvaluation = value || 'all'
		},
		setSelectedChartIdsEvaluationScope (state, value) {
			state.selectedChartIdsEvaluationScope = value || []
		},
		setExceedData (state, boolean) {
			state.exceedData = boolean
		},
		setResultBenchmarkData (state, { measureId, data }) {
			Vue.set(state.resultBenchmarkData, measureId, data)
		},
		setFilteredData (state, { chartId, data }) {
			Vue.set(state.filteredData, chartId, data)
		},
		setLoading (state, value) {
			state.loading = state.loading + value
		},
		setSeriesDefault (state, { measureId, data }) {
			Vue.set(state.seriesDefault, measureId, data)
		}
	},
	actions: {
		setSelectedEvaluation ({commit}, value) {
			commit('setSelectedEvaluation', value)
		},
		setSelectedChartIdsEvaluationScope({commit}, value) {
			commit('setSelectedChartIdsEvaluationScope', value)
		},
		reset ({ commit }) {
			commit('reset')
		},
		startLoading ({ commit }) {
			commit('setLoading', 1)
		},
		endLoading ({ commit }) {
			commit('setLoading', -1)
		},
		loadDataByFilter ({ state, commit }, { chartFilter, chartId, force = false, fromGlobal }) {
			if (chartFilter === undefined) {
				return
			}
			if (state.filteredData[chartId] !== undefined && force === false) {
				return
			}
			let filtered = fromGlobal

			if (chartFilter && chartFilter.length) {
				filtered = getFilteredData(filtered, chartFilter)
			}
			commit('setFilteredData', { chartId: chartId, data: Object.values(filtered).flat() })
		},
		filterDataByGlobalFilter (_, { measure, globalFilters, fromGlobal }) {
			const filter = globalFilters.filter(f => f.measure === measure.id || f.global)
			const filtered = getFilteredDataGlobal(measure.id, { [measure.id]: fromGlobal[measure.id] }, filter)
			return filtered
		},
		loadData ({ _, commit, dispatch}, { measure, daysBack }) {
			dispatch('startLoading')
			let prom = new Promise((resolve) => {
				let tmp = ''
				if (daysBack) {
					tmp = `&days_back=${daysBack}`
				}

				let url = `results/data/?eval=1&measure=${measure.id}${tmp}`
				ApiClient.get(url).then(response => {
					if (response.data[0] == 'exceedData') {

						commit('setExceedData',true)
						dispatch('endLoading')
						dispatch('endLoading')
						resolve(response.data[1])
					}
					else {
						commit('setExceedData',false)
						let measuresAttributesArr = response.data.flat()

						const included = measure.minus1included
						const inherit = measure.inherit_data

						const selectedAttributes = measure.selected_attributes?.flatMap(
							item => item.data.map(it => it.name_new || it.name)
						) || []

						measuresAttributesArr = [] // Initialize result array
						const chunkSize = 1000 // Define chunk size

						for (let i = 0; i < response.data.length; i += chunkSize) {
							const chunk = response.data.slice(i, i + chunkSize)

							for (const attributesInstance of chunk) {
								const attributes = attributesInstance.attributes

								// Filter and process attributes
								let filteredAttributes = Object.fromEntries(
									Object.entries(attributes).filter(([key, value]) =>
										(!selectedAttributes.length || selectedAttributes.includes(key)) &&
							value !== 'NA' &&
							(included === true || !['-1', -1].includes(value))
									)
								)

								// Add additional modifications if `inherit` is true
								let result = {
									...attributesInstance,
									attributes: filteredAttributes,
								}

								if (inherit === true) {
									result = { ...result, measure: measure.id }
								}

								// Push the processed result to the array
								measuresAttributesArr.push(result)
							}
						}

						if (measure.benchmarks.length === 0) {
							resolve(measuresAttributesArr)
							dispatch('endLoading')
							return
						}
						return ApiClient.get(`results/benchmarks/?ids=${measure.benchmarks.join(',')}`).then(res => {
							if (res) {
								commit('setResultBenchmarkData', { measureId: measure.id, data: res.data })
							}
							dispatch('endLoading')
							resolve(measuresAttributesArr)
						})
					}
				}).catch(() => {
					dispatch('endLoading')
					resolve()
				})

			})
			return prom
		},
		loadSeriesDefault ({ state, commit }, {measure, storeToGlobal}) {
			const selectedAttributes = []
			// make a deep copy of parent_attributes
			const filterParentBySelected = JSON.parse(JSON.stringify(measure.parent_attributes))

			// Prepare the selected-Array for the lookup
			if (measure.selected_attributes) {
				for (const i of measure.selected_attributes) {
					for (const i0 of i.data) {
						selectedAttributes?.push(i0.name_new)
					}
				}
			}

			if (filterParentBySelected) {
				for (const i of filterParentBySelected) {
					// Reverse loop to avoid index issues
					for (let j = i.data.length - 1; j >= 0; j--) {
						const i0 = i.data[j]
						if (!selectedAttributes?.includes(i0.name_new)) {
							// Remove the item from the array
							i.data.splice(j, 1)
						}
					}
				}
			}

			// filter out empty arrays from filterParentBySelected
			const filterEmptyArrays = measure?.is_parent_measure ? [] : filterParentBySelected?.filter(_ => _.data.length)
			const selectedAttr = measure?.is_parent_measure ? measure?.default_attributes : filterEmptyArrays
			const minus1included = measure.minus1included
			const result = []
			let tmpGroup = {}
			let obj = {}

			selectedAttr.forEach(group => {
				group.data.forEach(attribute => {
					if (attribute.type === 'groupByYear') {
						const measureYear = 2020
						tmpGroup = {}
						tmpGroup.measure = { id: measure.id, name: measure.name }
						tmpGroup.title = group.name
						tmpGroup.title_fr = group.name_fr
						tmpGroup.title_it = group.title_it
						tmpGroup.skala = group.skala || undefined
						tmpGroup.config = group.likert ? { [measure.id]: group.likert } : undefined
						tmpGroup.label = attribute.label || attribute.name
						tmpGroup.label_fr = attribute.label_fr
						tmpGroup.label_it = attribute.label_it
						tmpGroup.key = attribute.name_new
						tmpGroup.answerKeys = { [measure.id]: group.data.map(att => att.name_new) }
						tmpGroup.data = []

						const gap = parseInt(attribute.ageGap)
						const minAge = parseInt(attribute.minAge)
						const maxAge = parseInt(attribute.maxAge)
						const maxI = Math.round((maxAge - minAge) / gap)
						let currentMinAge = minAge
						for (let i = 0; i <= maxI; i++) {
							obj = {}
							obj.key = attribute.name_new
							obj.measure = { id: measure.id, name: measure.name }
							obj.label = i === maxI ? `${currentMinAge} +` : `${currentMinAge} - ${currentMinAge + gap - 1}`
							const funcMinAge = currentMinAge
							obj.customFunc = (value) => {
								let current = 0
								if (value > 1900) {
									current = measureYear - parseInt(value)
								} else {
									current = parseInt(value)
								}
								if (i === maxI) {
									return ((parseInt(value) > 0) && (current >= maxAge))
								} else {
									return ((parseInt(value) > 0) && (current >= funcMinAge) && (current <= funcMinAge + (gap - 1)))
								}
							}
							tmpGroup.data.push(obj)
							currentMinAge = currentMinAge + gap
						}
						result.push(tmpGroup)
					} else if (attribute.type === 'multi') {
						tmpGroup = {}
						tmpGroup.measure = { id: measure.id, name: measure.name }
						tmpGroup.title = group.name
						tmpGroup.title_fr = group.name_fr
						tmpGroup.title_it = group.title_it
						tmpGroup.skala = group.skala || undefined
						tmpGroup.config = group.likert ? { [measure.id]: group.likert } : undefined
						tmpGroup.label = attribute.label || attribute.name
						tmpGroup.label_fr = attribute.label_fr
						tmpGroup.label_it = attribute.label_it
						tmpGroup.key = attribute.name_new
						tmpGroup.answerKeys = { [measure.id]: attribute.options.map(att => att.name_new) }
						tmpGroup.data = []
						for (const option of attribute.options) {
							obj = {}
							obj.measure = { id: measure.id, name: measure.name }
							obj.key = attribute.name_new
							obj.label = option.label
							obj.customFunc = (value) => {
								let index = value.indexOf(option.name_new)
								if (!minus1included) {
									return Array.isArray(value) && index > -1 && value[index] !== -1
								}
								return Array.isArray(value) && index > -1
							}
							tmpGroup.data.push(obj)
						}
						result.push(tmpGroup)
					} else {
						tmpGroup = {}
						tmpGroup.measure = { id: measure.id, name: measure.name }
						tmpGroup.title = group.name
						tmpGroup.title_fr = group.name_fr
						tmpGroup.title_it = group.name_it
						tmpGroup.skala = group.skala || undefined
						tmpGroup.config = group.likert ? { [measure.id]: group.likert } : undefined
						tmpGroup.type = attribute.type
						tmpGroup.hide_in_only = attribute.hide_in_only
						tmpGroup.hide_in_graph = attribute.hide_in_graph
						tmpGroup.blow_in_graph = attribute.blow_in_graph
						tmpGroup.auto_grouping = attribute.auto_grouping
						tmpGroup.render_to_text = attribute.render_to_text
						tmpGroup.label = attribute.label || attribute.name
						tmpGroup.label_fr = attribute.label_fr
						tmpGroup.label_it = attribute.label_it
						tmpGroup.key = attribute.name_new
						tmpGroup.options = attribute.group
						if (attribute.type === 'multi_number') {
							tmpGroup.answerKeys = { [measure.id]: attribute.options.map(att => att.name_new) }
						} else {
							tmpGroup.answerKeys = { [measure.id]: group.data.map(att => att.name_new) }
						}

						// Speichere die Werte in das data attribut um zb. den filter auszuwählen
						tmpGroup.data = []
						const defaultAttribute = group.data.find(att => att.name_new === attribute.name_new)
						if (defaultAttribute === undefined) {
							return tmpGroup
						}

						if (defaultAttribute.map.length === 0 && defaultAttribute.type !== 'text' && defaultAttribute.type !== 'date_range') {
							const key = defaultAttribute.name_new
							let uniqueResult = []

							if (!state.exceedData) {
								let filteredData = Object.values(storeToGlobal).flat()
								filteredData = filteredData.filter(v => v !== undefined)
								uniqueResult = uniqBy(filteredData, v => [v.attributes[key]].join()).map(v => v.attributes[key])
							}

							const uniqueResultValue = state.exceedData? storeToGlobal[key]: uniqueResult
							const values = uniqueResultValue.filter(v => v !== null && v !== undefined)
							const filteredvalues = (!minus1included) ? values.filter(v => v !== -1) : values
							filteredvalues.forEach(v => {
								obj = {}
								obj.measure = { id: measure.id, name: measure.name }
								obj.label = v
								obj.key = key
								obj.value = v
								tmpGroup.data.push(obj)
							})
						} else {
							const filterAttributes = uniqBy(defaultAttribute.map, v => [v.from].join())
							const areAllNotFalsy = filterAttributes.every(v => [v.to].join())
							const uniqueValues = areAllNotFalsy ? uniqBy(filterAttributes, v => [v.to].join()) : filterAttributes
							const areAllLikert = uniqueValues.every((item) => item.to && item.from)
							if (areAllLikert) {
								uniqueValues.sort((a, b) => a.to - b.to)
							}
							uniqueValues.forEach(v => {
								obj = {}
								obj.measure = { id: measure.id, name: measure.name }
								obj.label = v.label || v.to || v.from
								obj.label_fr = v.label_fr
								obj.label_it = v.label_it
								obj.key = attribute.name_new
								if (attribute.type === 'grouped') {
									obj.customFunc = (value) => {
										return value > parseInt(v.from) && value < parseInt(v.to)
									}
								} else {
									obj.value = parseInt(v.to) || v.to || parseInt(v.from) || v.from
								}
								tmpGroup.data.push(obj)
							})
						}
						result.push(tmpGroup)
					}
				})
			})
			commit('setSeriesDefault', { measureId: measure.id, data: result })
		}
	}

}
