<style>
.custom-label input:checked ~ svg {
    display: block !important;
}
.custom-label input:checked ~ div.background {
    @apply bg-emerald-600;
}
</style>
<template>
  <label class="custom-label flex items-center font-normal cursor-pointer">
    <div class="relative border-2 w-5 h-5 flex justify-center items-center mr-2 " :class="[checkedProxy ? 'border-green-600': 'border-neutral-500' ]">
      <input
        type="checkbox"
        class="hidden"
        :value="val"
        v-model="checked"
        @change="$emit('input', checkedProxy)"
        :disabled="disabled"/>
      <div class="background absolute w-full h-full z-0"></div>
      <svg class="hidden w-5 h-5 text-white pointer-events-none z-10" viewBox="0 0 172 172"><g
        fill="none"
        stroke-width="none"
        stroke-miterlimit="10"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode:normal"><path d="M0 172V0h172v172z"/><path d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z" fill="currentColor" stroke-width="1"/></g></svg>
    </div>
    <slot></slot>
  </label>
</template>

<script>

export default {
	data () {
		return {
			checkedProxy: false
		}
	},
	props: {
		value: {
			type: null,
			required: true
		},
		val: {
			type: null,
			required: false
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		checked: {
			get () {
				return this.value || this.checkedProxy
			},
			set (val) {
				this.checkedProxy = val
				this.$emit('change', val)
			}
		}
	},
	mounted () {
		this.checkedProxy = this.value
	}
}
</script>
