import ApiClient from '@/assets/js/ApiClient.js'
import Crud from '../../crud'
import { i18n } from '@/main.js'
let crud = new Crud('patzu/tokens')

export default {
	namespaced: true,
	state: () => ({
		...crud.state
	}),
	getters: {
		...crud.getters,
		fields () {
			return [
				{ text: i18n.t('created'), value: 'created', format: 'DateTime', readOnly: true, hideCreate: true, hideEdit: true, width: 100 },
				{ text: 'Token', value: 'token', width: 100 },
				{ text: 'Auswertungseinheit', value: 'order_unit.name', order: 'order_unit__name', width: 350, readOnly: true },
				{ text: 'Spital', value: 'hospital_name', order: 'order_unit__order__hospital__name', width: 200, readOnly: true }
			]
		},
		importFields () {
			return ['anqid', 'category', 'token', 'unitname']
		},
		exportActions () {
			return [
				{
					name: 'Tokens',
					path: 'patzu/tokens/export/?byPermissions=0'
				}
			]
		},
		filters (state, getters, rootState, rootGetters) {
			return [
				{
					name: 'Kategorien',
					options: rootGetters['patzu/hospitals/categories'],
					field: 'order_unit__order__hospital__category',
					singleSelect: true
				},
				{
					name: 'Jahr',
					options: rootGetters.years.map(y => { return { id: y, value: y } }),
					field: 'order_unit__order__year',
					singleSelect: true
				}
			]
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions,
		importData ({ state }, data) {
			let prom = new Promise((resolve, reject) => {
				ApiClient.post(state.endpoint + '/import_data/', data).then(response => {
					resolve(response.data)
				}).catch((err) => {
					reject(err.response.data)
				})
			})
			return prom
		}
	}
}
