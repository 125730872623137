import Crud from '../../crud'

let crud = new Crud('accounts/responsibles')

export default {
	namespaced: true,
	state: () => ({
		...crud.state
	}),
	getters: {
		...crud.getters,
		fields () {
			return [
				{
					text: 'Benutzer',
					value: 'user',
					type: 'rel_select',
					isMulti: false,
					getterPath: 'accounts/users',
					hideList: true
				},
				{ text: 'Benutzer', width: -1, value: 'user_email', hideCreate: true, hideEdit: true, readOnly: true, order: 'user__email' },
				{ text: 'Verantwortlich', width: 150, value: 'is_responsible', format: 'BooleanView', type: 'boolean' }
			]
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions
	}
}
