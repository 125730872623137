
export default {
	namespaced: true,
	state: () => ({
		all: [],
		detailItem: null
	}),
	getters: {
		exportActions (state, getters, rootState, rootGetters) {
			const ct = rootGetters['accounts/contenttypes/getByName']('moniq.hospital').id
			return [
				{
					name: 'Benutzerliste',
					path: `accounts/users/export/?app_permissions__content_type=${ct}`
				},
				{
					name: 'Berechtigungsliste',
					path: `accounts/app_permissions/export/?content_type=${ct}`,
					relationship_id_field: 'user_ids'
				}
			]
		},
		filters (state, getters, rootState, rootGetters) {
			return [...rootGetters['accounts/users/filters'],
				{
					name: 'Kategorien',
					options: rootGetters['moniq/hospitals/categories'],
					field: 'moniq_category'
				}, {
					name: 'Verantwortlich',
					options: [{ id: 1, value: 'Ja' }, { id: 2, value: 'Nein' }, { id: 3, value: 'Keine Antwort' }],
					field: 'is_responsible',
					singleSelect: true
				}
			]
		},

		all: state => state.all,
		detailItem: state => state.detailItem,
		getById: (state) => (id) => { return state.all.find(o => o.id === parseInt(id)) },
		fields (state, getters, rootState, rootGetters) {
			return rootGetters['accounts/users/fields']
		},
		title (state, getters, rootState, rootGetters) {
			return rootGetters['accounts/users/title']
		},
		customAdd: () => '/accounts/invitations/+',
		pagination (state, getters, rootState, rootGetters) {
			return rootGetters['accounts/users/pagination']
		},
		canAddMailJob: () => true
	},
	mutations: {
		store (state, objects) {
			state.all = objects
		},
		storeDetail (state, item) {
			state.detailItem = item
		}
	},
	actions: {
		async load ({ commit, dispatch, rootGetters }, payload) {
			if (payload.query === undefined) {
				payload.query = {}
			}
			payload.query.app_permissions__content_type = rootGetters['accounts/contenttypes/getByName']('moniq.hospital').id
			const users = await dispatch('accounts/users/load', payload, { root: true })
			commit('store', rootGetters['accounts/users/all'])
			return users
		},
		async loadDetail ({ commit, dispatch, rootGetters }, id) {
			commit('storeDetail', null)
			await dispatch('accounts/users/loadDetail', id, { root: true })
			commit('storeDetail', rootGetters['accounts/users/detailItem'])
		},
		async update ({ dispatch }, payload) {
			return dispatch('accounts/users/update', payload, { root: true })
		},
		delete ({ dispatch }, payload) {
			return dispatch('accounts/users/delete', payload, { root: true })
		}
	}
}
