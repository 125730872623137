<template>
  <div>
    <div v-if="isDetailItemLoaded">
      <div v-if="canCreateEvalChildEvaluation && isEvaluationDetailPage">
        <div v-if="update">
          <section class="flex flex-row justify-between mb-2">
            <div
              v-tooltip="'Update Children Evaluation'"
              class="ml-1 mr-1 w-10 h-10 flex items-center justify-center
                    bg-neutral-800 hover:bg-neutral-600 dark:bg-neutral-600
                    dark:hover:bg-neutral-500 text-white cursor-pointer rounded-full"
              :class="{ 'opacity-50 cursor-not-allowed': !selectedEvalLocal }"
              :disabled="!selectedEvalLocal"
              @click="selectedEvalLocal ? openModal() : null"
            >
              <svg
                id="Layer_1"
                fill="#ffffff"
                height="30px"
                width="30px"
                version="1.2"
                baseProfile="tiny"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 448 512"
                xml:space="preserve"
              ><g id="SVGRepo_bgCarrier" stroke-width="0" /><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" /><g id="SVGRepo_iconCarrier"> <path d="M152 88a72 72 0 1 1 144 0A72 72 0 1 1 152 88zM39.7 144.5c13-17.9 38-21.8 55.9-8.8L131.8 162c26.8 19.5 59.1 30 92.2 30s65.4-10.5 92.2-30l36.2-26.4c17.9-13 42.9-9 55.9 8.8s9 42.9-8.8 55.9l-36.2 26.4c-13.6 9.9-28.1 18.2-43.3 25l0 36.3-192 0 0-36.3c-15.2-6.7-29.7-15.1-43.3-25L48.5 200.3c-17.9-13-21.8-38-8.8-55.9zm89.8 184.8l60.6 53-26 37.2 24.3 24.3c15.6 15.6 15.6 40.9 0 56.6s-40.9 15.6-56.6 0l-48-48C70 438.6 68.1 417 79.2 401.1l50.2-71.8zm128.5 53l60.6-53 50.2 71.8c11.1 15.9 9.2 37.5-4.5 51.2l-48 48c-15.6 15.6-40.9 15.6-56.6 0s-15.6-40.9 0-56.6L284 419.4l-26-37.2z" /> </g></svg>
            </div>
            <v-select
              v-model="selectedEvalLocal"
              :options="evaluationOptions"
              :label="$t('Update a Child Evaluation')"
              class="max-w-xs"
              @input="handleSelectionUpdate"
            />
          </section>
        </div>

        <div v-else>
          <v-select
            :options="formattedChildEvaluationsIds"
            :label="$t('Navigate to a Child Evaluation')"
            @input="handleSelection"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VSelect from '@/components/v-select'
import ApiClient from '@/assets/js/ApiClient.js'

export default {
	components: {
		'v-select': VSelect
	},
	props: {
		selectedEval: {
			type: Object,
			default: null
		},
		update: {
			type: Boolean,
			default: false
		},
		openUpdateEvalChildModal: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			childLabels: {}, // to add
			selectedEvalLocal: this.selectedEval
		}
	},
	computed: {
		...mapGetters({
			currentEvaluation: 'results/evaluations/detailItem',
			childrenIds: 'results/evaluations/childrenIds',
			canCreateEvalChildEvaluation: 'results/evaluations/canCreateEvalChildEvaluation',
		}),
		isDetailItemLoaded () {
			return !!this.$store.state.results.evaluations.detailItem
		},
		formattedChildEvaluationsIds () {
			return this.childrenIds.map((id) => {
				const childInfo = this.childLabels[id] || 'no label'
				return {
					label: `Evaluation ${id} - ${childInfo}`,
					href: `/results/evaluations/${id}`
				}
			})
		},
		restrictToFspAnq () {
			const current = this.currentEvaluation.measures
			let localData = [...this.$globalData.fspOnly,...this.$globalData.anqOnly]
			const ret = localData.some(item => current.includes(item))
			return ret
		},
		isEvaluationDetailPage () {
			return /^\/results\/evaluations\/\d+$/.test(this.$route.path)
		},
		evaluationOptions() {
			if (this.childrenIds.length === 0) {
				return [{ id: 'all', value: 'All children evaluations' }]
			}
			return [
				{ id: 'all', value: 'All children evaluations' },
				...this.childrenIds.map((id) => {
					const childInfo = this.childLabels[id] || 'no label'
					return ({
						id: id,
						value: `Evaluation ${id} - ${childInfo}`
					})
				})
			]
		},
	},
	methods: {
		...mapActions({
			setSelectedEvaluation: 'results/evaluationStore/setSelectedEvaluation',
		}),
		async fetchChildLabels () {
			for (const id of this.childrenIds) {
				if (!this.childLabels[id]) {
					const endpoint = `results/evaluations/${id}/get_child_label/`
					ApiClient.get(endpoint).then(response => {
						if (response.data) {
							const label = response.data.label || 'no label'
							this.$set(this.childLabels, id, label)
						} else {
							this.$set(this.childLabels, id, 'no label')
						}
					}).catch(err => {
						console.error(`Error fetching label for evaluation ${id}:`, err.response.data.detail)
						this.$set(this.childLabels, id, 'no label')
					})
				}
			}
		},
		handleSelection (selectedOption) {
			if (selectedOption && typeof selectedOption === 'object') {
				const id = this.extractIdFromHref(selectedOption.href)
				this.redirectToMeasure(id)
			}
		},
		handleSelectionUpdate (elem) {
			this.setSelectedEvaluation(elem)
			this.selectedEvalLocal = elem
		},
		extractIdFromHref (href) {
			const idMatch = href.match(/\/results\/evaluations\/(\d+)$/)
			return idMatch ? idMatch[1] : null
		},
		redirectToMeasure (id) {
			if (id) {
				this.$router.push({ path: `/results/evaluations/${id}` })
			}
		},
		async openModal () {
			this.$emit('update:openUpdateEvalChildModal', true)
		}
	},
	watch: {
		childrenIds (newIds) {
			if (newIds?.length) {
				this.fetchChildLabels() // Fetch labels when child measure IDs change
			}
		},
		selectedEval: {
			immediate: true,
			handler(newVal) {
				this.selectedEvalLocal = newVal
			}
		}
	},
	mounted () {
		if (this.childrenIds?.length) {
			this.fetchChildLabels()
		}
	}
}
</script>

  <style scoped>
  .dropdown {
    max-width: 300px;
  }
  </style>