import Crud from '../../crud'
import ApiClient from '@/assets/js/ApiClient.js'
import { i18n } from '../../../main.js'

let crud = new Crud('results/measures')

export default {
	namespaced: true,
	state: () => ({
		...crud.state,
		toolTip: null,
		children_selected_attributes: []
	}),
	getters: {
		...crud.getters,
		canClone: () => () => true,
		canCreateChildMeasure: (state) => {
			return state?.detailItem?.is_parent_measure
		},
		doesInheritData: (state) => {
			const isParent = state?.detailItem?.is_parent_measure
			const inheritData = state?.detailItem?.inherit_data
			const shouldHide = !isParent && (inheritData === null || inheritData)
			return shouldHide
		},
		childrenSelectedAttributes: state => state.children_selected_attributes,
		detailActions () {
			return [
				{ label: 'Template-Csv', action: 'downloadTemplate' },
				{ label: 'Importieren aus LS...', action: 'importFromLs' },
				{ label: 'Resultate löschen...', action: 'deleteResults', warningText: (item) => `Hiermit Löschen Sie alle Resultate zur Messung: <b>${item.name}</b>` }
			]
		},
		anqMeasureOnly (state) {
			return (!state?.detailItem?.is_parent_measure && [113, 61, 62, 63].includes(state?.detailItem?.parent_measure_id))
		},
		fspMeasureOnly (state) {
			return (!state?.detailItem?.is_parent_measure && [65, 66, 101].includes(state?.detailItem?.parent_measure_id))
		},
		childMeasureIds: (state) => {
			return state.detailItem?.child_measure_ids || []
		},
		active: state => state.all.filter(m => m.is_active),
		fileAttributes: state => state.fileAttributes,
		fileData: state => state.fileData,
		forSelection: state => state.all.map(m => ({
			id: m.id,
			value: m.is_parent_measure
				? m.name
				: m.label
					? `${m.name} - ${m.label}`
					: `${m.name} - (no label)`
		})),
		exampleValues: (state, getters) => (key) => {
			if (key === '') return ''
			let values = getters.fileData.filter(d => d[key] !== '').slice(0, 3).map(o => o[key]).join(', ')
			return values + ', ...'
		},
		fields (state, getters, rootState, rootGetters) {
			const options = rootGetters['accounts/contenttypes/results'].reduce((result, value) => {
				result.push({ id: value.id, value: i18n.t(`${value.app_label}.${value.model}`) })
				return result
			}, [])

			const features = [{ id: 'pdf', value: 'PDF-Export' }]

			return [
				{ text: 'Aktiv', help: 'Aktiviere automatische Synchronisation mit Limesurvey (Alle 60min)', value: 'is_active', hideEdit: getters.doesInheritData, width: 55, format: 'BooleanView', type: 'boolean' },
				{ text: 'Parent', value: 'is_parent_measure', type: 'boolean', format: 'BooleanView', hideCreate: true, hideEdit: true, width: 70 },
				{ text: 'Import läuft', help: 'Der Importjob is aktuell am laufen, falls es ein Fehler bei der synchronisation gegeben hat kann das Flag hier wieder deaktiviert werden.', value: 'is_importing', hideEdit: getters.doesInheritData, width: 55, format: 'BooleanView', type: 'boolean', hideList: true },
				{ text: 'Ist -1 ein gültiger Wert?', help: 'Per default wird -1 als NA umcodiert. Wenn das Flag gesetzt ist, werden -1 nicht als NA umcodiert.', value: 'minus1included', hideEdit: false, format: 'BooleanView', type: 'boolean' },
				{ text: 'Full Name', value: 'fullname', max_length: 120, default: '', required: false, hideEdit: true, hideCreate: true, showSelect: true },
				{ text: 'Name', value: 'name', max_length: 70, width: -1, default: '', required: true, hideEdit: !getters.canCreateChildMeasure, showSelect: true },
				{ text: 'Child label', value: 'label', max_length: 40, width: -1, default: '', required: false, hideEdit: getters.canCreateChildMeasure, hideCreate: true, showSelect: true },
				{
					text: 'Sprachen',
					value: 'languages',
					type: 'select',
					options: rootGetters.languages,
					multiselect: true,
					hideEdit: false
				},
				{
					text: 'Child Measures',
					value: 'child_measure_ids',
					type: 'custom',
					hideEdit: true,
					hideList: true,
					hideCreate: true,
					data: state.detailItem?.child_measure_ids || []
				},
				{ text: 'Key', help: 'Bezeichnung des Ordners für den manuellen Datei import vom Exoscale-Bucket', value: 'key', max_length: 50, width: 300, default: '', required: true, hideList: true },
				{ text: 'Features', value: 'features', type: 'select', multiselect: true, options: features, hideEdit: false, default: [], hideList: true },
				{ text: 'Objekt Typ', value: 'content_type', type: 'select', options: options, default: '', hideEdit: false, required: true, width: -1 },
				{ text: 'Umfrage ID', value: 'surveyId', type: 'integer', hideEdit: getters.doesInheritData, width: 100 },
				{ text: 'Import Zuweisung', values: ['import_object_ids', 'filter_map'], hideList: true, type: 'component', componentName: 'FilterMap', hideEdit: false, default: [], help: 'Definiere hier die Zuweisung von den Resultaten zu dem Objekt.' },
				{ text: 'Filter', value: 'filter', type: 'component', componentName: 'FilterItem', hideEdit: false, default: [], hideList: true, help: 'Filtere die Rohdaten anhand diesen Kriterien.' },
				{ text: 'Letzte importierte ID', value: 'lastImportId', type: 'integer', hideEdit: getters.doesInheritData, hideCreate: true, hideList: true },
				{ text: 'Letzer Import am', value: 'last_import_at', type: 'DateTime', format: 'DateTime', hideCreate: true, hideList: false, readOnly: true },
				{ text: 'Attribut-Gruppen', value: 'default_attributes', hideList: true, type: 'component', componentName: 'Attributes', default: [], help: 'Definiere hier die Attribute welche eingelesen werden sollen. Es kann zusätzlich noch eine Zuweisungsmatrix definiert werden falls die Werte umgeschreiben werden sollen.' },
				{ text: 'Resultate', value: 'results_count', order: 'results_count', hideCreate: true, hideEdit: true, width: 100 },
				{ text: 'Selected-Attribut', value: 'selected_attributes', hideCreate: true, hideEdit: true, hideList: true, defaut: [], help: 'Attributes manually selected from a child measure' },
				{ text: 'Parent_Attribut', value: 'parent_attributes', hideCreate: true, hideEdit: true, hideList: true, defaut: [], help: 'Attributes taken from the parent' }
			]
		}
	},
	mutations: {
		...crud.mutations,
		storeToolTip (state, item) {
			state.toolTip = item
		},
		setChildrenSelectedAttributes (state, childrenSelectedAttributes) {
			state.children_selected_attributes = childrenSelectedAttributes
		}
	},
	actions: {
		...crud.actions,
		// eslint-disable-next-line
    async beforeUpdate ({}, measure) {
			// remove empty attributes with no name
			measure.default_attributes.forEach(att => {
				const quests = att.data.filter(a => a.name !== '')
				att.data = quests
			})
			delete measure.results
			measure.filter = measure.filter.filter(f => f.key !== '' || f.value !== '')
			return measure
		},
		downloadTemplate ({ getters }, primaryKey) {
			let measure = getters.getById(primaryKey)
			let csvContent = 'data:text/csv;charset=utf-8,'

			const attribute = measure.default_attributes.map(group => group.data.map(item => item.name_new)).flat()
			const filter = measure.filter_map.map(f => f.to)
			const columns = [...attribute, ...filter]

			csvContent += columns.join(';')
			const data = encodeURI(csvContent)
			const link = document.createElement('a')
			link.setAttribute('href', data)
			link.setAttribute('download', 'import-template.csv')
			document.body.appendChild(link)
			link.click()
			link.remove()
		},
		importFromLs ({ commit, state, dispatch }, primaryKey) {
			let prom = new Promise(() => {
				dispatch('startLoading', null, { root: true })
				ApiClient.post(`${state.endpoint}/${primaryKey}/import_ls/`).then(response => {
					commit('update', response.data.measure)
					dispatch('addNotification', { title: 'Import', message: `Es wurden ${response.data.added} Resultate neu importiert.` }, { root: true })
				}).finally(() => {
					dispatch('endLoading', null, { root: true })
				})
			})
			return prom
		},
		// eslint-disable-next-line
    async updateParentAfterDeletion ({}, endpoint) {
			ApiClient.post(`${endpoint}`).then(() => {})
		},
		// eslint-disable-next-line
    async updateSelectedAttributes ({}, { endpoint, newBody }) {
			ApiClient.put(`${endpoint}`, newBody).then(() => {})
		},
		async deleteResults ({ commit, getters, rootGetters, dispatch }, primaryKey) {
			await dispatch('loadDetail', primaryKey)
			let measure = getters.detailItem
			dispatch('startLoading', null, { root: true })
			dispatch('enableNotification', false, { root: true })
			let count = 0
			await dispatch('results/data/load', { query: { measure: measure.id }, all: true }, { root: true })
			const results = JSON.parse(JSON.stringify(rootGetters['results/data/all']))
			for (const r in results) {
				await dispatch('results/data/delete', results[r], { root: true })
				count++
			}
			dispatch('enableNotification', true, { root: true })
			dispatch('addNotification', { title: 'Gelöscht', message: `Es wurden ${count} Resultate gelöscht.` }, { root: true })
			measure.results = []
			commit('update', measure)
			dispatch('endLoading', null, { root: true })
		}
	}
}
