<template>
  <div>
    <div v-if="isLoading">
      <loading-spinner/>
    </div>
    <div v-else>
      <div v-if="ordersError && ordersError.length" class="w-full rounded-lg mb-10">
        <h3 class="mb-2 font-bold">⚠️ Fehler Bestellungen</h3>
        <div
          v-for="(order, index) in ordersError"
          :key="order.id"
          class="flex flex-col w-full py-2 px-3 cursor-pointer hover:bg-neutral-100 dark:hover:bg-black border border-l-0 border-r-0 broder-b border-neutral-200 dark:border-neutral-800"
          :class="[index%2 === 0 ? '' : 'bg-neutral-50 dark:bg-neutral-900']"
        >
          <div class="flex justify-between w-full" @click="openOrder(order)">
            <div class="flex items-center space-x-2">
              <div>
                {{order.hospital.anqid}}
              </div>
              <div>
                {{order.hospital.name}}
              </div>
              <div>
                {{order.hospital.category_text}}
              </div>
            </div>
            <div class="flex items-center">
              <span class="mr-2">{{formatDateTime(order.modified)}}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="orders && orders.results.length" class="w-full rounded-lg mb-10">
        <h3 class="mb-2 font-bold">🛒 Offene Bestellungen <span>{{paginationOrders.count}}</span> 🛒 </h3>
        <div
          v-for="(order, index) in orders.results"
          :key="order.id"
          class="flex flex-col w-full py-2 px-3 cursor-pointer hover:bg-neutral-100 dark:hover:bg-black border border-l-0 border-r-0 broder-b border-neutral-200 dark:border-neutral-800"
          :class="[index%2 === 0 ? '' : 'bg-neutral-50 dark:bg-neutral-900']"
        >
          <div class="flex justify-between w-full" @click="selectOrder(order.id)">
            <div class="flex items-center space-x-2">
              <svg class="h-5 w-5 fill-current text-neutral-400 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm2 12l-4.5 4.5 1.527 1.5 5.973-6-5.973-6-1.527 1.5 4.5 4.5z"/></svg>
              <button @click.stop="releaseOrder(order)" v-if="order.print_type !== 5 && order.units.filter(u => u.state === 2).length" class="bg-secondary text-xs py-1 px-2 ml-2">Bestellung Freigeben</button>
              <div v-if="order.print_type === 5" class="bg-blue-600 text-white rounded-md text-xs py-1 px-2 ml-2">Direktversand</div>
              <div v-if="order.units.filter(u => u.state === 9).length" class="bg-emerald-600 text-white rounded-md text-xs py-1 px-2 ml-2">@Funke</div>

              <div v-if="order.units.filter(u => u.state < 9 && u.state > 2).length" class="bg-yellow-600 text-white rounded-md text-xs py-1 px-2 ml-2">In Bearbeitung</div>
              <div>
                {{order.hospital.anqid}}
              </div>
              <div>
                {{order.hospital.name}}
              </div>
              <div>
                {{order.hospital.category_text}}
              </div>
            </div>
            <div class="flex items-center">
              <span class="mr-2 text-xs">{{formatRelativeTime(order.modified)}} - {{formatDateTime(order.modified)}}</span>
              <div @click.stop="openOrder(order)" class="cursor-pointer text-xl">🛒</div>
            </div>
          </div>
          <div v-if="currentOrder == order.id" class="w-full mt-2">
            <div v-for="unit in order.units.filter(u => u.state < 10)" :key="unit.id" class="border-2 border-l-0 border-r-0 mb-2 border-neutral-300 px-7">
              <div v-for="state in orderStates.filter(item => item.id < 99)" :key="state.id">
                <order-unit :state="state" :unit="unit"/>
              </div>
            </div>
          </div>
        </div>
        <Pagination :paginationInitial="paginationOrders" @on-new-page="onNewPageOrders"/>
      </div>

      <div v-if="hospitals" class="w-full mt-3">
        <h3 class="mb-2 font-bold">⚠️ Ungültige Anzahl Administratoren ⚠️ </h3>
        <v-table
          :headers="headersHospital"
          :items="hospitals.results"
          :selectable="true"
          @click="onClick"/>
        <Pagination :paginationInitial="paginationAdminCheck" @on-new-page="onNewPageAdminCheck"/>
      </div>
    </div>
  </div>
</template>

<script>
import ApiClient from '@/assets/js/ApiClient.js'
import { mapGetters } from 'vuex'
import OrderUnit from '@/components/patzu/OrderUnit'
import VTable from '@/components/v-table'
import Pagination from '@/components/Pagination'
import dateMixin from '@/mixins/date'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default {
	mixins: [dateMixin],
	components: {
		'v-table': VTable,
		Pagination,
		OrderUnit,
		LoadingSpinner
	},
	data () {
		return {
			hospitals: null,
			orders: null,
			ordersError: null,
			currentOrder: null,
			isLoading: false
		}
	},
	computed: {
		...mapGetters({
			orderStates: 'patzu/orderunits/orderStates'
		}),
		headersHospital () {
			let tmp = this.$store.getters['patzu/hospitals/fields'].filter(f => f.showSelect)
			tmp.unshift({ text: 'Admins', value: 'admins', width: 80 })
			return tmp
		},
		headersOrder () {
			let tmp = this.$store.getters['patzu/orders/fields'].filter(f => f.showSelect)
			return tmp
		},
		paginationAdminCheck () {
			return {
				count: this.hospitals.count,
				current: this.hospitals.current,
				next: this.hospitals.next,
				previous: this.hospitals.previous,
				size: 5
			}
		},
		paginationOrders () {
			return {
				count: this.orders.count,
				current: this.orders.current,
				next: this.orders.next,
				previous: this.orders.previous,
				size: 10
			}
		}
	},
	methods: {
		async onNewPageAdminCheck (newPage) {
			this.hospitals = await this.$store.dispatch('patzu/hospitals/load', { callback: true, pagination: true, query: { checkPermissions: 1, state: 1, size: 5, page: newPage } })
		},
		async onNewPageOrders (newPage) {
			this.orders = await this.$store.dispatch('patzu/orders/load', { callback: true, pagination: true, query: { has_open_orders: 1, size: 10, page: newPage } })
		},
		onClick (item) {
			this.$router.push({ path: `patzu/hospitals/${item.id}` })
		},
		openOrder (order) {
			this.$router.push({ path: `patzu/orders/${order.id}` })
		},
		selectOrder (orderId) {
			if (this.currentOrder === orderId) {
				this.currentOrder = null
			} else {
				this.currentOrder = orderId
			}
		},
		releaseOrder (order) {
			const params = {
				title: 'Bist du sicher?',
				text: 'Möchtest du die Bestellung zum prozessieren freigeben?',
				onConfirm: () => {
					ApiClient.post(`/patzu/orders/${order.id}/approve/`).then(() => {
						this.reloadOrders()
					}).catch(err => {
						console.log(err)
					})
				}
			}
			this.$confirmModal.show(params)
		},
		async reloadOrders () {
			this.orders = await this.$store.dispatch('patzu/orders/load', { callback: true, pagination: true, query: { has_open_orders: 1, size: 10 } })
			this.ordersError = await this.$store.dispatch('patzu/orders/load', { callback: true, all: true, query: { has_error_orders: 1 } })
		}
	},
	async mounted () {
		this.isLoading = true
		await this.reloadOrders()
		this.hospitals = await this.$store.dispatch('patzu/hospitals/load', { callback: true, pagination: true, query: { checkPermissions: 1, state: 1, size: 5 } })
		this.isLoading = false
	}
}
</script>
