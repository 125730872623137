import Vue from 'vue'
import Vuex from 'vuex'
import { clone } from 'lodash'
import { i18n } from '@/main.js'
import router from '../router'
import appPermissions from './modules/accounts/app.permissions.js'
import files from './modules/filemanager/files.js'
import contenttypes from './modules/accounts/contenttypes.js'
import versions from './modules/accounts/versions.js'
import permissions from './modules/accounts/permissions.js'
import invitations from './modules/accounts/invitations.js'
import studekHospitals from './modules/studek/hospitals.js'
import patzuHospitals from './modules/patzu/hospitals.js'
import studekUsers from './modules/studek/users.js'
import patzuUsers from './modules/patzu/users.js'
import apps from './modules/apps/apps.js'
import appsGroups from './modules/apps/groups.js'
import addresses from './modules/patzu/addresses.js'
import orders from './modules/patzu/orders.js'
import events from './modules/patzu/eventmeasure.js'
import studekEvents from './modules/studek/eventmeasure.js'
import patzuMeasures from './modules/patzu/measures.js'
import studekMeasures from './modules/studek/measures.js'
import orderunits from './modules/patzu/orderunits.js'
import declarations from './modules/patzu/declarations.js'
import tokens from './modules/patzu/tokens.js'
import moniqHospitals from './modules/moniq/hospitals.js'
import moniqResponsibles from './modules/moniq/responsibles.js'
import patzuResponsibles from './modules/patzu/responsibles.js'
import moniqUsers from './modules/moniq/users.js'
import groups from './modules/accounts/groups.js'
import users from './modules/accounts/users.js'
import responsibles from './modules/accounts/responsibles.js'
import feedbacks from './modules/accounts/feedbacks.js'
import demoUsers from './modules/accounts/demo_users.js'

import usertitles from './modules/accounts/usertitles.js'
import measures from './modules/results/measures.js'
import results from './modules/results/data.js'
import importer from './modules/results/importer.js'
import evaluations from './modules/results/evaluations.js'
import benchmarks from './modules/results/benchmarks.js'
import evaluationStore from './modules/results/evaluationStore'
import mails from './modules/mailer/mails.js'
import jobs from './modules/mailer/jobs.js'
import templates from './modules/mailer/templates.js'
import lsapi from './modules/lsapi/lsapi.js'
import irpHospitals from './modules/irp/hospitals.js'
import irpGlobalFiles from './modules/irp/irpGlobalFiles.js'
import irpUserFiles from './modules/irp/irpUserFiles.js'
import irpUsers from './modules/irp/users.js'
import reviewYears from './modules/irp/review_years.js'
import reviewers from './modules/irp/reviewers.js'
import reviews from './modules/irp/reviews.js'

import ApiClient from '../assets/js/ApiClient.js'

Vue.use(Vuex)

// const databaseExistsAndReturnData = () => {
//   return new Promise((resolve, reject) => {
//     const dbName = 'loginData'
//     const dbVersion = 1
//     const request = indexedDB.open(dbName, dbVersion)

//     let db
//     let databaseExists = true

//     request.onupgradeneeded = (event) => {
//       // If onupgradeneeded is called, it means the database does not exist.
//       event.target.transaction.abort()
//       databaseExists = false
//     }

//     request.onsuccess = (event) => {
//       if (!databaseExists) {
//         resolve(false)
//         return
//       }

//       db = event.target.result
//       const transaction = db.transaction(['login'], 'readonly')
//       const store = transaction.objectStore('login')
//       const getRequest = store.get('loginPath')

//       getRequest.onsuccess = () => {
//         const data = getRequest.result
//         if (data) {
//           // Proceed to clear the database content
//           clearDatabaseContent(db).then(() => {
//             resolve(data.path) // Return the path if data exists
//           }).catch(error => {
//             console.log(error)
//           })
//         } else {
//           resolve(false) // No data present
//         }
//       }

//       getRequest.onerror = (event) => {
//         console.log(event.target.error)
//       }
//     }

//     request.onerror = (event) => {
//       console.log(event.target.error)
//     }
//   })
// }

// const clearDatabaseContent = (db) => {
//   return new Promise((resolve, reject) => {
//     const transaction = db.transaction(['login'], 'readwrite')
//     const store = transaction.objectStore('login')
//     const clearRequest = store.clear()

//     clearRequest.onsuccess = () => {
//       resolve()
//     }

//     clearRequest.onerror = (event) => {
//       reject(event.target.error)
//     }
//   })
// }

export default new Vuex.Store({
	modules: {
		lsapi: lsapi,
		accounts: {
			namespaced: true,
			modules: {
				appPermissions: appPermissions,
				groups: groups,
				users: users,
				invitations: invitations,
				contenttypes: contenttypes,
				versions: versions,
				permissions: permissions,
				usertitles: usertitles,
				responsibles: responsibles,
				feedbacks: feedbacks,
				demo_users: demoUsers
			}
		},
		apps: {
			namespaced: true,
			modules: {
				apps: apps,
				groups: appsGroups
			}
		},
		results: {
			namespaced: true,
			modules: {
				measures: measures,
				data: results,
				evaluations: evaluations,
				benchmarks: benchmarks,
				importer: importer,
				evaluationStore: evaluationStore
			}
		},
		patzu: {
			namespaced: true,
			modules: {
				hospitals: patzuHospitals,
				addresses: addresses,
				orders: orders,
				orderunits: orderunits,
				declarations: declarations,
				users: patzuUsers,
				responsibles: patzuResponsibles,
				tokens: tokens,
				events: events,
				measures: patzuMeasures
			}
		},
		moniq: {
			namespaced: true,
			modules: {
				hospitals: moniqHospitals,
				users: moniqUsers,
				responsibles: moniqResponsibles
			}
		},
		studek: {
			namespaced: true,
			modules: {
				hospitals: studekHospitals,
				users: studekUsers,
				events: studekEvents,
				measures: studekMeasures
			}
		},
		irp: {
			namespaced: true,
			modules: {
				hospitals: irpHospitals,
				irpGlobalFiles: irpGlobalFiles,
				irpUserFiles: irpUserFiles,
				review_years: reviewYears,
				reviews: reviews,
				reviewers: reviewers,
				users: irpUsers
			}
		},
		filemanager: {
			namespaced: true,
			modules: {
				files: files
			}
		},
		mailer: {
			namespaced: true,
			modules: {
				mails: mails,
				mailtemplates: templates,
				mailjobs: jobs
			}
		}
	},
	state: () => ({
		accessToken: localStorage.accessToken !== undefined ? localStorage.accessToken : null,
		refreshToken: localStorage.refreshToken !== undefined ? localStorage.refreshToken : null,
		spinning: 0,
		loading: 0,
		initialized: false,
		user: null,
		notifications: [],
		enableNotify: true,
		refUrl: null,
		fullPathUrl: null,
		editValues: null,
		userCharts: null,
		filters: [],
		tableItemsLength: 0,
		sortOrder: 1,
		sortKey: '',
		query: {},
		selection: [],
		customTitle: undefined
	}),
	getters: {
		customTitle: state => state.customTitle,
		isSpinning: state => state.spinning > 0,
		isLoading: state => state.loading > 0,
		user: state => state.user,
		isStaff: state => state.user.is_staff !== undefined && state.user.is_staff === true,
		userInitials: state => state.user.first_name.charAt(0).toUpperCase() + state.user.last_name.charAt(0).toUpperCase(),
		notifications: state => state.notifications,
		refUrl: state => state.refUrl,
		fullPathUrl: state => state.fullPathUrl,
		accessToken: state => state.accessToken,
		refreshToken: state => state.refreshToken,
		editValues: state => state.editValues,
		userCharts: state => state.userCharts,
		filters: state => state.filters,
		tableItemsLength: state => state.tableItemsLength,
		sortKey: state => state.sortKey,
		sortOrder: state => state.sortOrder,
		selection: state => state.selection,
		query: state => state.query,
		languages: () => [
			{ id: 1, value: i18n.t('german'), code: 'de' },
			{ id: 2, value: i18n.t('french'), code: 'fr' },
			{ id: 3, value: i18n.t('italian'), code: 'it' }
		],
		years () {
			let currentYear = new Date().getFullYear() + 1; let years = []
			let startYear = 2019
			while (startYear <= currentYear) {
				years.unshift(startYear++)
			}
			return years
		}
	},
	mutations: {
		setQuery (state, value) {
			state.query = value
		},
		setSelection (state, value) {
			state.selection = value
		},
		setSortOrder (state, value) {
			state.sortOrder = value
		},
		setSortKey (state, value) {
			state.sortKey = value
		},
		setEditValues (state, values) {
			state.editValues = clone(values)
		},
		setUserCharts (state, values) {
			state.userCharts = clone(values)
		},
		setTableItemsLength (state, count) {
			state.tableItemsLength = count
		},
		setFilter (state, filter) {
			state.filters.push(filter)
		},
		resetFilter (state) {
			state.filters = []
		},
		setRefUrl (state, url) {
			state.refUrl = url
		},
		setFullPathUrl (state, url) {
			state.fullPathUrl = url
		},
		setAccessToken (state, accessToken) {
			state.accessToken = accessToken
			localStorage.setItem('accessToken', accessToken)
		},
		setRefreshToken (state, refreshToken) {
			state.refreshToken = refreshToken
			localStorage.setItem('refreshToken', refreshToken)
		},
		resetState (state) {
			state.accessToken = null
			state.refreshToken = null
			localStorage.removeItem('accessToken')
			localStorage.removeItem('refreshToken')
		},
		setSpinning (state, value) {
			state.spinning = state.spinning + value
		},
		setLoading (state, value) {
			state.loading = state.loading + value
		},
		setInitialized (state, flag) {
			state.initialized = flag
		},
		setUser (state, user) {
			state.user = user
		},
		setUserPreviousLanguageToCurrent (state, currentLanguage) {
			state.user.language_previous = currentLanguage
		},
		addNotification (state, obj) {
			const id = Date.now().toString(36) + Math.random().toString(36).substring(2)
			obj.id = id
			state.notifications.push(obj)
		},
		removeNotification (state, notify) {
			const index = state.notifications.findIndex(n => n.id === notify.id)
			if (index !== -1) state.notifications.splice(index, 1)
		},
		setEnableNotify (state, value) {
			state.enableNotify = value
		},
		setCustomTitle (state, value) {
			state.customTitle = value
		}
	},
	actions: {
		updateCustomTitle ({ commit }, text) {
			commit('setCustomTitle', text)
		},
		updateFilter ({ commit }, filter) {
			commit('setFilter', filter)
		},
		resetFilter ({ commit }) {
			commit('resetFilter')
		},
		// save evaluation btn
		saveItem ({ commit, getters, dispatch }, payload) {
			let prom = new Promise((resolve, reject) => {
				const payloadUpdate = [getters.editValues, getters.userCharts]
				dispatch(`${payload.path}/${payload.action}`, payloadUpdate).then((succeed) => {
					if (succeed) {
						if (payload.close) {
							commit('setEditValues', null)
							commit('setUserCharts', null)
						}
						commit(`${payload.path}/storeDetail`, getters.editValues)
						commit(`${payload.path}/storeDetailUserCharts`, getters.userCharts)
						resolve(succeed)
					}
				}).catch(err => {
					dispatch('addNotification', { title: i18n.t('error'), message: err, error: true })
					reject(err)
				})
			})
			return prom
		},
		deleteItem ({ commit, getters, dispatch }, collectionStr) {
			let prom = new Promise((resolve, reject) => {
				dispatch(collectionStr, getters.editValues).then(() => {
					commit('setEditValues', null)
					resolve()
				}).catch(err => {
					reject(err)
				})
			})
			return prom
		},
		updateRefUrl ({ commit }, url) {
			commit('setRefUrl', url)
		},
		updateFullPathUrl ({ commit }, url) {
			commit('setFullPathUrl', url)
		},
		enableNotification ({ commit }, payload) {
			commit('setEnableNotify', payload)
		},
		addNotification ({ commit, state }, payload) {
			if (state.enableNotify) {
				commit('addNotification', payload)
			}
		},
		startSpinning ({ commit }) {
			commit('setSpinning', 1)
		},
		endSpinning ({ commit }) {
			commit('setSpinning', -1)
		},
		startLoading ({ commit }) {
			commit('setLoading', 1)
		},
		endLoading ({ commit }) {
			commit('setLoading', -1)
		},
		changeUserPreviousLanguageToCurrent ({ commit }, payload) {
			commit('setUserPreviousLanguageToCurrent', payload)
		},
		loadMe ({ commit, dispatch }) {
			let prom = new Promise((resolve) => {
				dispatch('startLoading')
				ApiClient.get('accounts/users/me/').then(response => {
					const user = response.data
					commit('setUser', user)
				}).finally(() => {
					dispatch('endLoading')
					resolve()
				})
			})
			return prom
		},
		addTokenToBlacklist () {
			const refreshToken = localStorage.refreshToken
			if (refreshToken) {
				ApiClient.post('api/token/blacklist/', { refresh: refreshToken }
				).catch(err => {
					for (const [key, value] of Object.entries(err.response.data)) {
						this.addNotification({ title: `Feld: ${key}`, message: value[0] || value, error: true }, { root: true })
					}
				})
			}
		},
		async logoutUser ({ commit, dispatch }, param) {
			await dispatch('addTokenToBlacklist')
			commit('setEditValues', null)
			commit('setUserCharts', null)
			commit('accounts/contenttypes/reset')
			commit('accounts/usertitles/reset')
			commit('filemanager/files/reset')
			commit('accounts/appPermissions/reset')
			commit('accounts/permissions/reset')
			commit('accounts/groups/reset')
			commit('accounts/users/reset')
			commit('accounts/responsibles/reset')
			commit('accounts/feedbacks/reset')
			commit('accounts/demo_users/reset')
			commit('apps/apps/reset')
			commit('apps/groups/reset')
			commit('results/measures/reset')
			commit('results/data/reset')
			commit('results/evaluations/reset')
			commit('results/benchmarks/reset')
			commit('accounts/invitations/reset')
			commit('patzu/hospitals/reset')
			commit('patzu/addresses/reset')
			commit('patzu/orders/reset')
			commit('patzu/declarations/reset')
			commit('patzu/orderunits/reset')
			commit('patzu/tokens/reset')
			commit('moniq/hospitals/reset')
			commit('studek/hospitals/reset')
			commit('irp/hospitals/reset')
			commit('irp/review_years/reset')
			commit('irp/reviews/reset')
			commit('irp/reviewers/reset')
			commit('mailer/mailtemplates/reset')
			commit('mailer/mails/reset')
			commit('mailer/mailjobs/reset')
			commit('patzu/events/reset')
			commit('patzu/measures/reset')
			commit('studek/events/reset')
			commit('studek/measures/reset')
			commit('lsapi/reset')
			commit('resetState')
			commit('setInitialized', false)
			const query = param !== undefined && param !== null ? param : ''
			// try {
			//   const data = await databaseExistsAndReturnData()
			//   if (data) {
			//     // console.log('used login route ====', data)
			//     router.replace(data + query)
			//   } else {
			// console.log('The database either does not exist or has no data.')
			const loginRouteUsed = localStorage.getItem('usedLoginRoute') || '/login'
			router.replace(loginRouteUsed + query)
			// }
			// } catch (error) {
			//   console.error('Error checking database:', error)
			// }
		},
		updateAccessToken ({ commit }, accessToken) {
			commit('setAccessToken', accessToken)
		},
		updateRefreshToken ({ commit }, refreshToken) {
			commit('setRefreshToken', refreshToken)
		},
		async initialize ({ commit, dispatch }) {
			await dispatch('loadMe')
			await dispatch('accounts/contenttypes/load')
			await dispatch('accounts/appPermissions/load')
			await dispatch('accounts/usertitles/load')
			commit('setInitialized', true)
		}
	}
})
