<template>
  <header class="bg-white dark:bg-neutral-800 shadow dark:shadow-neutral-600 fixed mt-16 w-full" :class="[openCreateChildModal || openCreateEvalChildModal ? 'z-50' : 'z-30']">
    <div class="mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
      <h1
        class="text-3xl font-bold text-neutral-900 dark:text-neutral-200 text-center w-full md:text-left truncate"
        :class="[!hasSideNavigation ? 'md:text-center' : 'md:text-left md:pl-48 -ml-5']"
      >
        <span v-if="isTrashView">{{ $t('trash') }}: </span>
        <span :title="title" class="cursor-pointer hover:underline"> {{ title }} </span>
      </h1>
      <portal-target name="toolbarExt" />
      <div v-if="showToolbar" class="flex items-center">
        <measure-details class="mr-1" />
        <evaluation-details v-if="(fspMeasureOnly || anqMeasureOnly) && isStaff" class="mr-1" />
        <!-- <evaluation-details class="mr-1" /> -->
        <FilterTable v-if="$route.params.app && !$route.params.primaryKey && !hasCustomItemView" />
        <import-base-csv v-if="importFields" :fields="importFields" :import-data="importFunc" />
        <export-csv v-if="canExport" :actions="exportActions" />
        <portal-target name="toolbar" />
        <template v-if="!editValues && !$route.params.primaryKey">
          <div
            v-if="!isLoading && canAddMailJob"
            v-tooltip="'Mailjob aus Auswahl erstellen'"
            class="w-10 h-10 flex items-center justify-center bg-blue-700 hover:bg-blue-600 dark:bg-blue-800 text-white cursor-pointer rounded-full mr-2"
            @click="addMailJob()"
          >
            <svg class="fill-current w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z" /></svg>
          </div>
          <router-link
            v-if="!isLoading && !disableAdd && isStaffinEvaluationOnly"
            :to="addUrl"
            class="flex items-center justify-center p-2 w-10 h-10 bg-neutral-800 hover:bg-neutral-600 dark:bg-neutral-700 dark:hover:bg-neutral-600 text-white cursor-pointer rounded-full"
          >
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" /></svg>
          </router-link>
          <div
            v-if="!isLoading && canDeleteMulti"
            v-tooltip="$t('delete')+'...'"
            class="w-10 h-10 flex items-center justify-center bg-red-600 hover:bg-red-700 text-white cursor-pointer rounded-full ml-2"
            @click="deleteItems"
          >
            <svg class="fill-current w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" /></svg>
          </div>
          <MoreMenu class="ml-2 bg-neutral-800 hover:bg-neutral-600 w-10 h-10 p-2 rounded-full text-white cursor-pointer dark:bg-neutral-600 dark:hover:bg-neutral-500" :path="path" :action-path-name="'actions'" />
        </template>
        <template v-else>
          <div
            v-if="!isLoading && (canCreateChildMeasure ||canCreateEvalChildEvaluation) && (fspMeasureOnly || anqMeasureOnly) && isStaff"
            v-tooltip="$t('create-child-measure')"
            class="ml-1 mr-1 w-10 h-10 flex items-center justify-center
                bg-neutral-800 hover:bg-neutral-600 dark:bg-neutral-600
                dark:hover:bg-neutral-500 text-white cursor-pointer rounded-full"
            @click="openModal"
          >
            <svg
              id="Layer_1"
              fill="#ffffff"
              height="30px"
              width="30px"
              version="1.2"
              baseProfile="tiny"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="-351 153 256 256"
              xml:space="preserve"
            ><g id="SVGRepo_bgCarrier" stroke-width="0" /><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" /><g id="SVGRepo_iconCarrier"> <path d="M-184.5,155.4c11.5,0,20.9,9.4,20.9,20.9c0,11.5-9.4,20.9-20.9,20.9s-20.9-9.4-20.9-20.9 C-205.4,164.8-196,155.4-184.5,155.4z M-116.2,239.2l-29.1-29.1c-5.1-5.1-11.8-7.6-18.5-7.6h-20.7l0,0h-21.4 c-6.7,0-13.4,2.6-18.5,7.7l-50.6,50.6c-3.6,3.6-3.6,9.4,0,12.9c3.6,3.6,9.4,3.6,12.9,0l44.2-44.2c0.5-0.5,1.1-0.8,1.8-0.8 c1.4,0,2.6,1.2,2.6,2.6v23.5v44.5v94.2c0,7.2,5.9,13.1,13.1,13.1c7.2,0,13.1-5.9,13.1-13.1v-91.6c0-1.4,1.2-2.6,2.6-2.6 s2.6,1.2,2.6,2.6v91.6c0,7.2,5.9,13.1,13.1,13.1s13.1-5.9,13.1-13.1v-94.2l0,0v-15.5c3.3,1.3,7.2,0.7,9.9-2l29.6-29.6 C-112.7,248.6-112.7,242.8-116.2,239.2z M-155.7,265.7v-33.8c0-1.4,1.2-2.6,2.6-2.6c0.7,0,1.4,0.3,1.8,0.8l15.6,15.6L-155.7,265.7z M-295,279.7c8.6,0,15.6,7,15.6,15.6c0,8.6-7,15.6-15.6,15.6c-8.6,0-15.6-7-15.6-15.6C-310.6,286.7-303.6,279.7-295,279.7z M-278.7,314.8h-32.6c-8.2,0.4-14.8,7.2-14.8,15.5v21.5c0.1,3.2,2.7,5.7,5.8,5.7s5.8-2.6,5.8-5.7v-18c0-1.1,0.9-1.9,2-1.9 c1.1,0,1.9,0.9,1.9,1.9v63.6c0,3.8,3,6.8,6.8,6.8s6.8-3,6.8-6.8v-32.1c0-1.1,0.9-1.9,1.9-1.9c1.1,0,1.9,0.9,1.9,1.9v32.1 c0,3.8,3,6.8,6.8,6.8c3.8,0,6.8-3,6.8-6.8v-63.6c0-1.1,0.8-1.9,1.9-1.9s2,0.9,2,1.9v18c0.1,3.2,2.7,5.7,5.8,5.7 c3.1,0,5.8-2.6,5.8-5.7v-21.5C-263.9,322-270.4,315.2-278.7,314.8z" /> </g></svg>
          </div>
          <div
            v-if="!isLoading && !isNewItem && canDelete && isAllowedToEditEvaluation"
            v-tooltip="$t('delete')"
            class="w-10 h-10 flex items-center justify-center bg-red-600 hover:bg-red-700 text-white cursor-pointer rounded-full"
            @click="deleteItem"
          >
            <svg class="fill-current w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" /></svg>
          </div>
          <div
            v-if="!isLoading && !isNewItem && canClone && (canCreateChildMeasure || !isInMeasureOnly) && checkPermissionAdmin"
            v-tooltip="$t('save-as-copy')"
            class="ml-1 w-10 h-10 flex items-center justify-center bg-neutral-800 hover:bg-neutral-600 dark:bg-neutral-600 dark:hover:bg-neutral-500 text-white cursor-pointer rounded-full"
            @click="cloneItem"
          >
            <svg class="fill-current w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18 6v-6h-18v18h6v6h18v-18h-6zm-12 10h-4v-14h14v4h-10v10zm16 6h-14v-14h14v14zm-3-8h-3v-3h-2v3h-3v2h3v3h2v-3h3v-2z" /></svg>
          </div>
          <i
            v-if="!isLoading && !(isNewItem && customAddView) && (canSave === undefined || canSave === true || isNewItem) && isAllowedToEditEvaluation"
            v-tooltip="$t('save')"
            class="p-2 ml-1 bg-neutral-500 hover:bg-neutral-600 text-white material-icons cursor-pointer rounded-full dark:bg-neutral-600 dark:hover:bg-neutral-500"
            @click="saveItem(false)"
          >
            save
          </i>
          <i
            v-if="!isLoading && !(isNewItem && customAddView) && (canSave === undefined || canSave === true || isNewItem) && isAllowedToEditEvaluation"
            v-tooltip="$t('save-and-close')"
            class="p-2 ml-1 bg-neutral-800 hover:bg-neutral-600 text-white material-icons cursor-pointer rounded-full dark:bg-neutral-600 dark:hover:bg-neutral-500"
            @click="saveItem(true)"
          >
            done
          </i>
          <i
            v-if="user.is_staff && hasHistory"
            v-tooltip="'Versionen'"
            class="p-2 ml-1 bg-neutral-800 hover:bg-neutral-600 text-white material-icons cursor-pointer rounded-full dark:bg-neutral-600 dark:hover:bg-neutral-500"
            @click="showHistory"
          >
            history
          </i>
          <div v-if="openCreateChildModal" class="fixed mpointer-events-none w-full h-full top-0 left-0 flex items-center justify-center p-12 z-60">
            <div class="absolute w-full h-full bg-neutral-900 opacity-50"></div>

            <div class="flex flex-col bg-white w-1/3 mx-auto rounded shadow-lg z-50 overflow-hidden">
              <div class="w-full flex items-center p-2 bg-white z-40 mr-10">
                <h1 class="flex-grow">
                  {{ }}
                </h1>
              </div>
              <!-- Add margin if you want to see some of the overlay behind the modal-->
              <div class="p-3 flex-grow">
                <label class="text-base">Do you want to inherit parent's data?</label>
                <select v-model="selected">
                  <option class="" selected disabled value="Please select one">
                    Please select one
                  </option>
                  <option value="Child inherit parent's data. You CAN'T import the data to this child measure.">
                    Inherit data
                  </option>
                  <option value="Child NOT inherit parent's data. You NEED manually import data in the child measure.">
                    Don't inherit data
                  </option>
                </select>
                <span class="">Selected: {{ selected }}</span>
              </div>
              <!-- Optional extension to the child's name -->
              <div class="p-3 mt-4">
                <label class="text-base block mb-1">Optional extension to the child's name</label>
                <input v-model="childNameExtension" type="text" class="w-full py-2 px-3 border-2 border-gray-300 rounded" placeholder="Enter extension" />
              </div>
              <div class="w-full flex space-x-3 px-3 py-3 bg-white">
                <button class="cancel w-full flex items-center justify-center py-2 px-4" @click="closeModal">
                  CANCEL
                </button>
                <button class="primary w-full" @click="createChildMeasure">
                  CREATE CHILD MEASURE
                </button>
              </div>
            </div>
          </div>
          <div v-if="openCreateEvalChildModal" class="fixed inset-0 flex items-center justify-center p-12 z-60">
            <div class="absolute inset-0 bg-neutral-900 opacity-50"></div>
            <div class="relative flex flex-col bg-white w-1/3 mx-auto rounded-lg shadow-lg z-50 overflow-hidden">
              <div class="w-full flex items-center p-4 border-b border-gray-200 bg-white">
                <h1 class="flex-grow text-lg font-bold text-gray-800">
                  Confirmation
                </h1>
              </div>
              <div class="p-6 text-center">
                <p class="text-base text-gray-700">
                  Creation of a child evaluation based on the selected parent's charts
                </p>
              </div>
              <div class="p-6">
                <label class="text-base block mb-2 text-gray-700 text-center">Add a Label to the child Evaluation</label>
                <input v-model="labelEval" type="text" class="w-full py-2 px-3 border-2 border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none" placeholder="Enter label" />
              </div>
              <div class="w-full flex space-x-4 px-6 py-4 bg-gray-100 border-t border-gray-200">
                <button class="w-full py-2 px-4 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition-all" @click="closeModal">
                  CANCEL
                </button>
                <button class="w-full py-2 px-4 text-white rounded-lg transition-all" :class="labelEval ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-300 cursor-not-allowed'" :disabled="!labelEval" @click="createEvalChildMeasure">
                  OK
                </button>
              </div>
            </div>
          </div>
        </template>
        <i
          v-if="user.is_staff && isTrashView"
          v-tooltip="'Wiederherstellen'"

          class="p-2 ml-1 bg-neutral-800 hover:bg-neutral-600 text-white material-icons cursor-pointer rounded-full dark:bg-neutral-600 dark:hover:bg-neutral-500"
          @click="restore"
        >
          history
        </i>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FilterTable from '@/components/FilterTable.vue'
import ImportBaseCsv from './ImportBaseCsv.vue'
import ExportCsv from './ExportCsv.vue'
import MoreMenu from '@/components/MoreMenu.vue'
import MeasureDetails from '@/components/MeasureDetails.vue'
import EvaluationDetails from '@/components/EvaluationDetails.vue'
import ApiClient from '@/assets/js/ApiClient.js'

export default {
	components: {
		FilterTable,
		ImportBaseCsv,
		ExportCsv,
		MoreMenu,
		MeasureDetails,
		EvaluationDetails
	},
	props: ['hasSideNavigation'],
	data () {
		return {
			openCreateChildModal: false,
			openCreateEvalChildModal: false,
			selected: 'Please select one',
			childNameExtension: null,
			labelEval: ''
		}
	},
	computed: {
		...mapGetters({
			isStaff: 'isStaff',
			editValues: 'editValues',
			userCharts: 'userCharts',
			user: 'user',
			query: 'query',
			isLoading: 'isLoading',
			selection: 'selection',
			tableItemsLength: 'tableItemsLength',
			measureById: 'results/measures/getById',
			currentBenchmark: 'results/benchmarks/detailItem',
			currentEvaluation: 'results/evaluations/detailItem',
			getContentTypeByName: 'accounts/contenttypes/getByName',
			childrenSelectedAttributes: 'results/measures/childrenSelectedAttributes',
			parentCharts: 'results/evaluations/parentCharts',
			charts: 'results/evaluations/charts',
			selectedCharts: 'results/evaluations/selected_charts_ids',
			selectedChartsIdsForChildren: 'results/evaluations/selectedChartsIdsForChildren',
			canCreateChildMeasure: 'results/measures/canCreateChildMeasure',
			canCreateEvalChildEvaluation: 'results/evaluations/canCreateEvalChildEvaluation',
			fspMeasureOnly: 'results/evaluations/fspMeasureOnly',
			anqMeasureOnly: 'results/evaluations/anqMeasureOnly',
		}),
		isStaff () {
			return this.user.is_staff === true
		},
		isAdmin () {
			return this.currentEvaluation?.users_admin.includes(this.user.id) || (this.currentEvaluation?.view_access_admins && this.currentEvaluation?.view_access_admins.includes(this.user.id))
		},
		checkPermissionAdmin () {
			// Ein Administrator muss auch alle Berechtigungen zu den Messungen bzw Gruppen haben welche in dieser Messung definiert wurden
			if (this.isAdmin && !this.user.is_staff) {
				for (const mId of this.currentEvaluation.measures) {
					const measure = this.measureById(mId)
					if (measure === undefined) {
						return false
					}
				}
				return true
			}
			return true
		},
		isAllowedToEditEvaluation () {
			const isInEvaluations = this.$route.path.startsWith('/results/evaluations/')

			if (isInEvaluations) {
				return this.user?.is_staff || this.user?.id === this.currentEvaluation?.creator_id
			}
			return true
		},
		isInMeasureOnly () {
			return this.$route.path.startsWith('/results/measures/')
		},
		isStaffinEvaluationOnly () {
			return this.isStaff || this.$route.path.startsWith('/accounts/invitations')
		},
		isTrashView () {
			return this.$route.query.is_active === 'false'
		},
		importFields () {
			return this.$store.getters[`${this.$route.params.app}/${this.$route.params.collection}/importFields`]
		},
		exportActions () {
			return this.$store.getters[`${this.$route.params.app}/${this.$route.params.collection}/exportActions`]
		},
		customAddView () {
			return this.$store.getters[`${this.$route.params.app}/${this.$route.params.collection}/customAddView`]
		},
		hasCustomItemView () {
			return this.$store.getters[`${this.$route.params.app}/${this.$route.params.collection}/customItemsView`] !== undefined
		},
		title () {
			if (this.$route.params.app === undefined) {
				if (this.$store.getters.customTitle !== undefined) {
					return this.$store.getters.customTitle
				}
				if (this.$route.name) {
					return this.$te(this.$route.name) ? this.$t(this.$route.name) : this.$route.name
				}
				return 'w hoch 2 Apps'
			}
			if (this.$store.getters[`${this.path}/title`] && this.$route.params.primaryKey) {
				return this.$store.getters[`${this.path}/title`](this.$route.params.primaryKey)
			}
			if (this.editValues && this.editValues.name && this.editValues.label) {
				return this.editValues.fullname
			}
			if (this.editValues && this.editValues.name) {
				return this.editValues.name
			}
			return this.$t(`${this.$route.params.app}.${this.$route.params.collection}`)
		},
		labelHasChanged () {
			const fullname = this.editValues.fullname
			const currentLabel = this.editValues.label
			const separator = '- '
			const lastIndex = fullname.lastIndexOf(separator)
			let newLabel = ''
			if (lastIndex !== -1) {
				newLabel = fullname.substring(lastIndex + separator.length)
			}
			if (newLabel !== currentLabel) {
				return true
			}
			return false
		},
		disableAdd () {
			return this.$store.getters[`${this.path}/disableAdd`] || this.isTrashView
		},
		hasHistory () {
			return this.$store.getters[`${this.path}/hasHistory`]
		},
		isNewItem () {
			return this.$route.params.primaryKey === '+'
		},
		canDelete () {
			const canDelete = this.$store.getters[`${this.path}/canDelete`]
			return canDelete === undefined ? true : canDelete(this.$route.params.primaryKey)
		},
		canDeleteMulti () {
			const canDelete = this.$store.getters[`${this.path}/canDeleteMulti`]
			return (canDelete === true) && this.query && Object.keys(this.query).length && this.user.is_staff === true
		},
		canAddMailJob () {
			const canAddMailJob = this.$store.getters[`${this.path}/canAddMailJob`]
			return canAddMailJob !== undefined && this.query && Object.keys(this.query).length && this.user.is_staff === true
		},
		canClone () {
			const canClone = this.$store.getters[`${this.path}/canClone`]
			return canClone === undefined ? false : this.editValues && canClone(this.editValues)
		},
		canCreateChildMeasure () {
			const getCanCreateChildMeasure = this.$store.getters[`${this.path}/canCreateChildMeasure`]
			return getCanCreateChildMeasure
		},
		canCreateEvalChildEvaluation () {
			const getcanCreateEvalChildEvaluation = this.$store.getters[`${this.path}/canCreateEvalChildEvaluation`]
			return getcanCreateEvalChildEvaluation
		},
		canExport () {
			const canExport = this.$store.getters[`${this.path}/exportActions`]
			return canExport === undefined ? false : this.$route.params.primaryKey === undefined && this.user.is_staff === true
		},
		canSave () {
			const canSave = this.$store.getters[`${this.path}/canSave`]
			return canSave === undefined ? true : this.editValues && canSave(this.editValues)
		},
		showToolbar () {
			return this.$route.params.app !== undefined
		},
		path () {
			return `${this.$route.params.app}/${this.$route.params.collection}`
		},
		addUrl () {
			if (this.$store.getters[`${this.path}/customAdd`]) {
				return this.$store.getters[`${this.path}/customAdd`]
			}
			return `${this.$route.params.collection}/+`
		},
		isMeasureDetailPage () {
			return /^\/results\/measures\/\d+$/.test(this.$route.path)
		}
	},
	methods: {
		...mapActions({
			updateParentAfterDeletion: 'results/measures/updateParentAfterDeletion',
			updateSelectedAttributes: 'results/measures/updateSelectedAttributes',
			updateEvaluationParentAfterDelection: 'results/evaluations/updateParentAfterDeletion',
			updateEvaluationCharts: 'results/evaluations/updateEvalCharts'
		}),
		importFunc (data) {
			return this.$store.dispatch(`${this.$route.params.app}/${this.$route.params.collection}/importData`, data)
		},
		updateChildrenAfterParentUpdate () { // focus on deletion
			const childrenSelectedAttributes = this.childrenSelectedAttributes
			if (childrenSelectedAttributes) {
				const updateSeletedAttributes = async (measureId, body) => {
					const newBody = { selected_attributes: body }
					const endpoint = this.$route.params.app + '/' + this.$route.params.collection + '/' + measureId + '/update_selected_attributes/'
					await this.updateSelectedAttributes({ endpoint, newBody })
				}
				for (const childSelected of childrenSelectedAttributes) {
					const measureId = childSelected.childId
					const body = childSelected.selected_attributes
					updateSeletedAttributes(measureId, body)
				}
			}
		},
		updateChildrenAfterEvalParentUpdate (evaluationId) {
			// const charts = this.selected_charts // must be parent
			const charts = this.charts // keep it so before button for selected_charts is implemented
			// console.log('chart', charts)
			if (charts) {
				const updateCharts = async (evaluationId) => {
					const newBody = { charts: charts }
					const endpoint = this.$route.params.app + '/' + this.$route.params.collection + '/' + evaluationId + '/update_charts/'
					await this.updateEvaluationCharts({ endpoint, newBody })
				}
				if (evaluationId) {
				  updateCharts(evaluationId)
				}
			}
		},
		deleteItem () {
			// check if I am in some measure/evaluation, then send 'updateParentAfterDeletion' resp. 'updateEvaluationParentAfterDelection' request, where I delete child Id from parent array
			if (((this.$route.params.collection === 'measures') || (this.$route.params.collection === 'evaluations')) && !!this.$route.params.primaryKey) {
				const updateParent = (isMeasure) => {
					const endpoint = this.$route.path + '/update_parent_after_deletion/'
					if (isMeasure) {
						this.updateParentAfterDeletion(endpoint)
					} else {
						this.updateEvaluationParentAfterDelection(endpoint)
					}
				}
				updateParent((this.$route.params.collection === 'measures'))
			}

			const params = {
				title: 'Löschen',
				text: 'Möchten Sie wirklich löschen?',
				type: 'warning',
				onConfirm: () => {
					this.$store.dispatch('deleteItem', `${this.path}/delete`).then(() => {
						this.back()
					})
				}
			}
			this.$confirmModal.show(params)
		},
		async restore () {
			for (const item of this.selection) {
				await this.$store.dispatch(`${this.path}/restore`, item)
			}
			this.$router.push(`/${this.path}`)
		},
		async openModal () {
			if (this.isMeasureDetailPage) {
				this.openCreateChildModal = true
			}
			else {
				this.openCreateEvalChildModal = true
			}
		},
		async closeModal () {
			if (this.isMeasureDetailPage) {
				this.openCreateChildModal = false
				this.selected = 'Please select one'
			} else {
				this.openCreateEvalChildModal = false
			}
		},
		async deleteItems () {
			const items = await this.$store.dispatch(`${this.path}/load`, { query: { ...this.query, onlyId: 1 }, all: true, callback: true })
			const params = {
				title: 'Löschen',
				text: `Möchten Sie wirklich alle <b>${items.length} Objekte </b> löschen?`,
				type: 'critical',
				onConfirm: async () => {
					let stop = false
					let count = 0
					this.$store.dispatch('enableNotification', false, { root: true })
					// progress
					const param2 = {
						title: 'Löschen',
						count: items.length,
						onCancel: () => {
							stop = true
						}
					}
					this.$progressDlg.show(param2)
					for (const item of items) {
						if (stop) {
							break
						}
						await this.$store.dispatch(`${this.path}/delete`, item)
						count += 1
						this.$progressDlg.step()
					}
					this.$store.dispatch('enableNotification', true, { root: true })
					this.$confirmModal.show({
						title: 'Information',
						text: `<b>${count} Objekte </b> wurden erfolgreich gelöscht.`,
						type: 'info'
					})
				}
			}
			this.$confirmModal.show(params)
		},
		async addMailJob () {
			let query = {}
			let path = ''
			if (this.$route.params.app !== 'users' && this.$route.params.collection !== 'users') {
				const objects = await this.$store.dispatch(`${this.path}/load`, { query: this.query, callback: true, all: true })
				const ids = objects.map(obj => obj.id)
				query.app_permissions__content_type = this.getContentTypeByName(`${this.$route.params.app}.${this.$route.params.collection.slice(0, -1)}`).id
				query.object_ids = ids.join(',')
				query.role = 1

				path = 'accounts/users/load'
			} else {
				query = this.query
				path = `${this.path}/load`
			}
			const users = await this.$store.dispatch(path, { query: query, all: true, callback: true })
			this.$store.dispatch('mailer/mailjobs/setUsers', users)
			this.$router.push({ path: '/mailer/mailjobs/+' })
		},
		openDatabase () {
			const dbName = 'myDatabase'
			const dbVersion = 1
			const request = indexedDB.open(dbName, dbVersion)

			request.onupgradeneeded = (event) => {
				// This will run if it's the first time opening or version has changed
				/* */const db = event.target.result
				db.createObjectStore('myObjectStore', {
					keyPath: 'id',
					autoIncrement: true
				}) // Assuming 'id' as my primary key
			}

			request.onsuccess = (event) => {
				this.db = event.target.result
			}

			request.onerror = (event) => {
				console.error('Error opening database', event)
			}
		},
		deleteMeasureData (measureId) {
			return new Promise((resolve) => {
				ApiClient.post(`results/measures/${measureId}/remove_data_cache/`)
					.then(() => {
						return ApiClient.post(`cache/delete/${measureId}/`)
					})
					.then(response => {
						console.log('API calls successful:', response)
						resolve(response)
					})
					.catch(error => {
						console.error('Error in API calls (continuing anyway):', error)
						resolve()
					})
			})
		},
		deleteMeasureDataUser (userId) {
			return new Promise((resolve) => {
				ApiClient.get(`accounts/users/${userId}/measures/`)
					.then(response => {
						const measureIds = response.data

						const deletePromises = measureIds.map(measureId => this.deleteMeasureData(measureId))

						// here we wait for deletions of measure to end
						return Promise.all(deletePromises)
					})
					.then(responses => {
						console.log('All measures deleted successfully:', responses)
						resolve(responses)
					})
					.catch(error => {
						console.error('Error in deleting measures for user:', error)
						resolve()
					})
			})
		},
		saveItem (close) {
			const Id = this.$route.params.primaryKey

			const performSaveActions = () => {
				const action = this.isNewItem ? 'add' : 'update'

				if (this.path === 'results/measures' && Id && this.canCreateChildMeasure) {
					this.updateChildrenAfterParentUpdate()
				}

				if (this.isMeasureDetailPage) {
					const tmp = { ...this.editValues, fullname: `${this.editValues.name} - ${this.editValues.label}` }
					this.$store.commit('setEditValues', tmp)
				}

				this.$store.dispatch('saveItem', { path: this.path, action: action, close: close })
					.then((res) => {
						if (close) {
							this.$router.replace(`/${this.path}`)
						} else if (this.isNewItem) {
							this.$router.replace(`/${this.path}/${res.id}`)
						}

						if (this.$route.path === `/results/measures/${Id}`) {
							this.deleteMeasureData(Id)
								.then(() => {
									console.log('delete successful')
								})
								.catch(error => {
									console.error('Error deleting measure data:', error)
								})
						}

						if (this.$route.path === `/accounts/users/${Id}`) {
							this.deleteMeasureDataUser(Id)
								.then(() => {
									console.log('delete successful')
								})
								.catch(error => {
									console.error('Error deleting measure data:', error)
								})
						}

						if (this.$route.path.startsWith('/results/evaluations/')) {
							if (this.path === 'results/evaluations' && Id && this.canCreateEvalChildEvaluation) {
								this.updateChildrenAfterEvalParentUpdate(Id)
							}
						}

						if (this.$route.path.startsWith('/results/benchmarks/')) {
							for (const m of this.currentBenchmark.benchmark_measures) {
								this.deleteMeasureData(m)
									.then(() => {
										console.log('delete successful')
									})
									.catch(error => {
										console.error('Error deleting measure data:', error)
									})
							}
						}
					})
			}

			performSaveActions()
		},
		showHistory () {
			this.$emit('showHistory')
		},
		cloneItem () {
			if (this.editValues.name !== undefined) {
				const tmp = this.editValues
				tmp.name = `${tmp.name} (Kopie)`
				this.$store.commit('setEditValues', tmp)
			}
			if (this.editValues.key !== undefined) {
				const tmp = this.editValues
				tmp.key = `${tmp.key}_kopie`
				this.$store.commit('setEditValues', tmp)
			}
			if (this.editValues.creator !== undefined) {
				const tmp = this.editValues
				tmp.creator = this.user
				tmp.creator_id = this.user.id
				this.$store.commit('setEditValues', tmp)
			}
			if (this.path === 'results/evaluations') {
				const tmp = this.editValues
				tmp.view_access_object_ids = []
				tmp.view_access_admins = []
				tmp.view_access_users = []
				tmp.users_admin = []
				this.$store.commit('setEditValues', tmp)
			}
			if (this.path === 'irp/review_years') {
				const tmp = this.editValues
				tmp.is_copy = true
				// tmp.hospitals = []
				this.$store.commit('setEditValues', tmp)
			}
			this.$store.dispatch('saveItem', { path: this.path, action: 'add', close: true }).then(() => {
				this.back()
			})
		},
		createEvalChildMeasure () {
			if (this.editValues.name !== undefined) {
				const tmp = this.editValues
				const count = tmp.child_evaluation_count + 1 // start from 1
				this.childNameExtension = `Child ${count}`
				tmp.name = `${tmp.name} - ${this.childNameExtension}`
				this.$store.commit('setEditValues', tmp)
			}
			if (this.editValues.creator !== undefined) {
				const tmp = this.editValues
				tmp.creator = this.user.id
				this.$store.commit('setEditValues', tmp)
			}
			if (!this.editValues.parent_measure_id) {
				const tmp = this.editValues
				tmp.is_parent_evaluation = false
				tmp.child_evaluation_ids = []
				tmp.parent_evaluation_id = parseInt(this.$route.params.primaryKey)
				this.$store.commit('setEditValues', tmp)
			}

			// similar to a copy, we do not inherits rights
			const tmp = this.editValues
			tmp.view_access_object_ids = []
			tmp.view_access_admins = []
			tmp.view_access_users = []
			tmp.users_admin = []
			this.$store.commit('setEditValues', tmp)

			if (this.editValues.label == '') {
				const tmp = this.editValues
				tmp.label = this.labelEval ? this.labelEval : 'no label'
				this.$store.commit('setEditValues', tmp)
			}
			this.openCreateEvalChildModal = false

			this.$store.dispatch('saveItem', { path: this.path, action: 'add', close: true }).then(() => {
				this.back()
			})
		},
		createChildMeasure () {
			if (this.editValues.name !== undefined) {
				const tmp = this.editValues
				const count = tmp.child_measure_count + 1 // start from 1
				tmp.label = ''
				tmp.fullname = ''
				if (this.childNameExtension) {
					tmp.label = this.childNameExtension
					tmp.fullname = `${tmp.name} - ${this.childNameExtension}`
				} else {
					this.childNameExtension = `Child ${count}`
					tmp.label = this.childNameExtension
					tmp.fullname = `${tmp.name} - ${this.childNameExtension}`
				}
				this.$store.commit('setEditValues', tmp)
			}
			if (this.editValues.key !== undefined) {
				const tmp = this.editValues
				const count = tmp.child_measure_count + 1 // start from 1
				tmp.key = `${tmp.key}_child_${count}`
				this.$store.commit('setEditValues', tmp)
			}
			if (this.editValues.creator !== undefined) {
				const tmp = this.editValues
				tmp.creator = this.user.id
				this.$store.commit('setEditValues', tmp)
			}
			if (!this.editValues.parent_measure_id) {
				const tmp = this.editValues
				tmp.is_parent_measure = false
				tmp.child_measure_ids = []
				tmp.parent_measure_id = parseInt(this.$route.params.primaryKey)
				this.$store.commit('setEditValues', tmp)
			}
			if (!this.editValues.inherit_data && this.selected === "Child inherit parent's data. You CAN'T import the data to this child measure.") {
				const tmp = this.editValues
				tmp.inherit_data = true
				this.$store.commit('setEditValues', tmp)
			}
			if (!this.editValues.inherit_data && this.selected === "Child NOT inherit parent's data. You NEED manually import data in the child measure.") {
				const tmp = this.editValues
				tmp.inherit_data = false
				this.$store.commit('setEditValues', tmp)
			}

			this.openCreateChildModal = false

			this.$store.dispatch('saveItem', { path: this.path, action: 'add', close: true }).then(() => {
				this.back()
			})
		},
		back () {
			if (this.path === 'accounts/users' && parseInt(this.$route.params.primaryKey) === this.user.id) {
				if (this.hasBrowserHistory()) {
					this.$router.go(-1)
				} else {
					this.$router.replace('/')
				}
			} else {
				this.$router.replace(`/${this.path}`)
			}
		},
		hasBrowserHistory () {
			return window.history.length > 2
		},
		reload () {
			this.$store.dispatch(`${this.path}/load`)
		}
	},
	mounted () {
	}
}
</script>
