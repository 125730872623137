import ApiClient from '@/assets/js/ApiClient.js'
import Crud from '../../crud'

let crud = new Crud('mailer/jobs')

export default {
	namespaced: true,
	state: () => ({
		...crud.state,
		users: []
	}),
	getters: {
		...crud.getters,
		users: (state) => state.users,
		hasHistory: () => true,
		contentType: (state, getters, rootState, rootGetters) => rootGetters['accounts/contenttypes/getByName']('mailer.mailjob').id,
		customAddView: () => 'mailer/AddMailJob',
		customEditView: () => 'mailer/EditMailJob',
		detailActions () {
			return [
				{ label: 'Job starten...', action: 'startJob', isDisabled: (item) => item.state !== 0 || item.recipients.length === 0 },
				{ label: 'Job stoppen...', action: 'stopJob', isDisabled: (item) => item.state === 0 }
			]
		},
		fields () {
			return [
				{
					text: 'Status',
					value: 'state',
					width: 100,
					renderItem: (value) => {
						if (value === 0) {
							return '<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-neutral-500 rounded">Inaktiv</span>'
						}
						if (value === 1) {
							return '<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-500 rounded">Läuft</span>'
						}
						if (value === 2) {
							return '<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-emerald-500 rounded">Abgeschlossen</span>'
						}
					}
				},
				{ text: 'Nächster versand', value: 'send_at', format: 'DateTime', width: 150, readOnly: true },
				{ text: 'Name', value: 'name', width: -1 },
				{
					text: 'Status',
					value: 'progress',
					width: 50,
					renderItem: (value) => {
						const tmp = Math.round(value * 100.0)
						return `${tmp} %`
					}
				},
				{
					text: 'Gesendet',
					value: 'recipients',
					width: 100,
					renderItem: (value, item) => {
						return `${item.recipients_sent.length} von ${item.recipients.length + item.recipients_sent.length}`
					}
				},
				{ text: 'Erstellt am', value: 'created', format: 'DateTime', width: 150, readOnly: true },
				{ text: 'Geändert am', value: 'modified', format: 'DateTime', width: 150, readOnly: true }

			]
		}
	},
	mutations: {
		...crud.mutations,
		setUsers (state, users) {
			state.users = users
		}
	},
	actions: {
		...crud.actions,
		setUsers ({ commit }, users) {
			commit('setUsers', users)
		},
		startJob ({ commit, state, dispatch }, primaryKey) {
			let prom = new Promise(() => {
				dispatch('startLoading', null, { root: true })
				ApiClient.patch(`${state.endpoint}/${primaryKey}/`, { state: 1 }).then(response => {
					commit('update', response.data)
					dispatch('addNotification', { title: 'Gestartet', message: 'E-Mail versand wurde gestartet' }, { root: true })
				}).finally(() => {
					dispatch('endLoading', null, { root: true })
				})
			})
			return prom
		},
		stopJob ({ commit, state, dispatch }, primaryKey) {
			let prom = new Promise(() => {
				dispatch('startLoading', null, { root: true })
				ApiClient.patch(`${state.endpoint}/${primaryKey}/`, { state: 0 }).then(response => {
					commit('update', response.data)
					dispatch('addNotification', { title: 'Gestoppt ', message: 'E-Mail versand wurde gestoppt' }, { root: true })
				}).finally(() => {
					dispatch('endLoading', null, { root: true })
				})
			})
			return prom
		}
	}
}
