
const globalData = {
	exceedData: 130000,
	newColorPalette: [71],
	anqOnly: [113, 61, 62, 63],
	fspOnly: [65, 66, 101],
	moniqOnly: [14],
	results: {
		evaluationStore: {
			fullData: { },
			resultData: { },
			filteredData: { }
		}
	},
	colorArray: ['#D9B161','#E8D0A0','#F6ECD9','#D8D8D8','#6CC5B1','#009B78','#307767','#7F7F7F'],
	// colorArray0: ['#D0ECE6','#6BC5B1','#009B78','#2F7767','#BBAB73','#D9B161','#D8D8D8'],
	colorArray0:  ['#009B78', '#9CD8CB', '#E8D0A0', '#D9B161', '#D8D8D8'],
	colorArray1: ['#00E396', '#008FFB', '#FEB019', '#FF4560', '#775DD0', '#3F51B5', '#4CAF50', '#F9CE1D', '#FF9800', '#33B2DF', '#546E7A', '#D4526E', '#13D8AA', '#A5978B']
}

export default globalData

