<template>
  <p class="text-center text-white text-xs">
    w² Apps {{app_version}} &copy; {{year}} · <a class="hover:font-bold" href="https://www.w-hoch2.ch" target="_blank">w hoch 2 GmbH</a>
  </p>
</template>

<script>
import dayjs from 'dayjs'
export default {
	computed: {
		year () {
			return dayjs().year()
		},
		app_version () {
			return process.env.VUE_APP_VERSION
		}
	}
}
</script>
