import Crud from '../../crud'
let crud = new Crud('irp/review_years')

export default {
	namespaced: true,
	state: () => ({
		...crud.state
	}),
	getters: {
		...crud.getters,
		hasHistory: () => false,
		canClone: () => () => {
			return true
		},
		fields (state, getters, rootState, rootGetters) {
			return [
				{
					text: 'Jahr',
					value: 'year',
					width: -1,
					type: 'select',
					showSelect: true,
					options: rootGetters.years.map(y => { return { id: y, value: y } })
				},
				{
					text: 'Spital',
					value: 'hospitals',
					type: 'rel_select',
					isMulti: true,
					getterPath: 'irp/hospitals',
					customFields: rootGetters['irp/hospitals/fields'].filter(h => h.showSelect || h.value === 'is_active'),
					hideList: true
				},
				{ text: 'Teilnehmer', value: 'hospitals', width: 100, hideEdit: true, hideCreate: true, renderItem: (value) => value.length },
				{ text: 'Copy', value: 'is_copy', type: 'boolean', format: 'BooleanView', hideCreate: true, hideEdit: true, showSelect: true, width: 70 },
			]
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions
	}
}
