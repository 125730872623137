<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div v-if="pagination && needed" class="bg-white dark:bg-black px-4 py-3 flex items-center justify-between border-t border-neutral-200 dark:border-neutral-700 sm:px-6">
    <div class="flex-1 flex justify-between sm:hidden">
      <a href="#" class="relative inline-flex items-center px-4 py-2 border border-neutral-300 text-sm font-medium rounded-md text-neutral-700 bg-white hover:text-neutral-500">
        Previous
      </a>
      <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-neutral-300 text-sm font-medium rounded-md text-neutral-700 bg-white hover:text-neutral-500">
        Next
      </a>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between flex-wrap">
      <div>
        <p class="text-sm text-neutral-700 dark:text-neutral-300">
          {{ $t('show') }}
          <span class="font-medium">{{showFrom}}</span>
          {{ $t('to') }}
          <span class="font-medium">{{Math.min(pagination.count, showTo)}}</span>
          {{ $t('from') }}
          <span class="font-medium">{{pagination.count}}</span>
          {{ $t('objects') }}
        </p>
      </div>
      <div>
        <nav class="cursor-pointer relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          <div v-if="currentPage > 1" @click="updatePage(currentPage-1)" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-neutral-300 dark:border-l-neutral-700 bg-white dark:bg-black text-sm font-medium text-neutral-500 hover:bg-neutral-50 dark:hover:bg-neutral-900">
            <span class="sr-only">Previous</span>
            <!-- Heroicon name: solid/chevron-left -->
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true">
              <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
          </div>
          <template v-if="currentPage - 4 > 1">
            <div
              class="cursor-pointer relative inline-flex items-center px-4 py-2 border border-neutral-300 dark:border-l-neutral-700 bg-white dark:bg-black text-sm font-medium text-neutral-700 dark:text-neutral-300 hover:bg-neutral-50 dark:hover:bg-neutral-900"
              @click="updatePage(1)"
            >
              {{1}}
            </div>
            <span class="relative inline-flex items-center px-4 py-2 border border-neutral-300 dark:border-neutral-700 bg-white dark:bg-black text-sm font-medium text-neutral-700 dark:text-neutral-300">
              ...
            </span>
          </template>
          <div
            v-for="n in endPagenator"
            :key="n"
            class="cursor-pointer relative inline-flex items-center px-4 py-2 border border-neutral-300 dark:border-neutral-700 bg-white dark:bg-black text-sm font-medium text-neutral-700 dark:text-neutral-300 hover:bg-neutral-50 dark:hover:bg-neutral-900"
            :class="{'bg-neutral-200' : currentPage === startPagenator + n}"
            @click="updatePage(startPagenator + n)"
          >
            {{startPagenator + n}}
          </div>
          <template v-if="currentPage + 4 < maxPages">
            <span class="cursor-none relative inline-flex items-center px-4 py-2 border border-neutral-300 dark:border-neutral-700 bg-white dark:bg-black text-sm font-medium text-neutral-700 dark:text-neutral-300">
              ...
            </span>
            <div
              class="cursor-pointer relative inline-flex items-center px-4 py-2 border border-neutral-300 dark:border-neutral-700 bg-white dark:bg-black text-sm font-medium text-neutral-700 dark:text-neutral-300 hover:bg-neutral-50 dark:hover:bg-neutral-900"
              @click="updatePage(maxPages)"
            >
              {{maxPages}}
            </div>
          </template>
          <div v-if="currentPage+1 <= maxPages" @click="updatePage(currentPage+1)" class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-neutral-300 dark:border-neutral-700 bg-white dark:bg-black text-sm font-medium text-neutral-700 dark:text-neutral-300 hover:bg-neutral-50 dark:hover:bg-neutral-900">
            <span class="sr-only">Next</span>
            <!-- Heroicon name: solid/chevron-right -->
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true">
              <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
            </svg>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>

export default {
	props: {
		path: {
			type: String,
			default: ''
		},
		query: {
			type: Object,
			default: () => {}
		},
		paginationInitial: {
			type: Object,
			default: () => null
		},
		loadAdminsAndSimpleUsers: {
			type: Function,
			default: () => null
		}
	},
	components: {
	},
	computed: {
		needed () {
			return this.pagination.count > this.pageSize
		},
		pagination () {
			if (this.paginationInitial) {
				return this.paginationInitial
			}
			return this.$store.getters[`${this.path}/pagination`]
		},
		pageSize () {
			return this.pagination.size
		},
		maxPages () {
			return this.pagination ? Math.ceil(this.pagination.count / this.pageSize) : 0
		},
		startPagenator () {
			return Math.max(Math.min(this.currentPage - 5, this.maxPages - this.endPagenator), 0)
		},
		endPagenator () {
			return Math.min(9, this.maxPages)
		},
		currentPage () {
			return this.pagination.current
		},
		showFrom () {
			return this.currentPage * this.pageSize - this.pageSize + 1
		},
		showTo () {
			return this.currentPage * this.pageSize
		}
	},
	methods: {
		async updatePage (newPage) {
			this.loadAdminsAndSimpleUsers()
			if (this.paginationInitial) {
				this.$emit('on-new-page', newPage)
				return
			}
			const query = Object.assign({}, this.query)
			query.page = newPage
			query.size = this.pagination.size
			await this.$store.dispatch(`${this.path}/load`, { query: query })
		}
	}
}
</script>
