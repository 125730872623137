import Crud from '../../crud'

let crud = new Crud('accounts/versions')

export default {
	namespaced: true,
	state: () => ({
		...crud.state
	}),
	getters: {
		...crud.getters,
		canDeleteMulti: () => false,
		disableAdd () { return true },
		detailPath: () => (item) => {
			return `/${item.content_type.app_label}/${item.content_type.model}s/${item.object_id}`
		},
		fields () {
			return [
				{ text: 'Erstellt', width: 150, value: 'revision.date_created', format: 'DateTime', order: 'revision__date_created' },
				{ text: 'Benutzer', width: 300, value: 'revision.user.email' },
				{ text: 'Objekt', value: 'object_repr' }
			]
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions
	}
}
