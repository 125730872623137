import Crud from '../../crud'
import { i18n } from '@/main.js'

let crud = new Crud('accounts/invitations')

export default {
	namespaced: true,
	state: () => ({
		...crud.state
	}),
	getters: {
		...crud.getters,
		filters (state, getters, rootState, rootGetters) {
			if (!rootGetters.isStaff) {
				return null
			}
			return [
				{
					name: 'Akzeptiert',
					options: [{ selected: true, value: 'Ja' }, { selected: false, value: 'Nein' }],
					field: 'accepted'
				},
				{
					name: 'Status',
					options: [{ selected: 0, value: 'active' }, { selected: 1, value: 'expired' }],
					field: 'status'
				}
			]
		},
		isSelectable: (state, getters, rootState, rootGetters) => { return rootGetters.isStaff },
		customAddView: () => 'AddInvitation',
		open: state => { return state.all.filter(o => o.accepted === false).length },
		fields (state, getters, rootState, rootGetters) {
			const templates = rootGetters['mailer/mailtemplates/all'].map(t => { return { id: t.id, value: t.name } })
			const user = rootGetters.user
			return [
				{ text: i18n.t('created'), value: 'created', format: 'DateTime', readOnly: true, hideCreate: true, width: 160 },
				{ text: i18n.t('accepted'), value: 'accepted', readOnly: true, hideCreate: true, format: 'BooleanView', type: 'boolean', width: 100 },
				{ text: i18n.t('email'), value: 'email', type: 'email' },
				{ text: i18n.t('invited-from'), value: 'inviter.email', order: 'inviter__email', readOnly: true, hideCreate: true },
				{
					text: i18n.t('template'),
					value: 'template',
					type: 'select',
					options: templates,
					hideEdit: true,
					hideList: true
				},
				{
					text: i18n.t('language'),
					value: 'language',
					type: 'select',
					width: 100,
					options: [
						{ id: 1, value: i18n.t('german') },
						{ id: 2, value: i18n.t('french') },
						{ id: 3, value: i18n.t('italian') }
					]
				},
				{ text: i18n.t('force_2fa'), value: 'force_2fa', format: 'BooleanView', type: 'boolean', width: 100, hideList: !user.is_staff, hideEdit: !user.is_staff, hideCreate: !user.is_staff },
				{ text: i18n.t('staff'), value: 'is_staff', format: 'BooleanView', type: 'boolean', width: 100, hideList: !user.is_staff, hideEdit: !user.is_staff, hideCreate: !user.is_staff },
				{ text: i18n.t('permissions'), value: 'details', order: 'details_count', type: 'component', componentName: 'AppPermissions', hideList: !user.is_staff },
				{ text: 'expired', value: 'is_expired', readOnly: true, hideCreate: true, format: 'BooleanView', type: 'boolean', width: 100 },
			]
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions,
		async emailExists ({ dispatch }) {
			dispatch('addNotification', { title: 'Note', message: i18n.t('email-exists') }, { root: true })
		}
	}
}
